import React, { Component } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import { Collapse } from "reactstrap";
import Calculator from "../components/Calculator";
import SmartCalculator from "./SmartCalculator";
import Vocab from "./Vocab";
import { connect } from "react-redux";
import Speech from "speak-tts";
import { CSVLink } from "react-csv";
//import SpeechToTextPollyfill from "./speechToTextPollyfill";
import {
  changeClearOn,
  changeClearInput,
  changeSelectOn,
  changeSelectInput,
  changeJoinOn,
  changeJoinInput,
  changeRemoveOn,
  changeRemoveInput,
  changeSheetOn,
  changeSheetInput,
  changeQuestionOn,
  changeQuestionInput,
  changeHintOn,
  changeVideoOn,
  changeFullScreenOn,
  changePlayOn,
  changeShowOn,
} from "../actions/action";
import SpeechToText from "./speechToText";

import "../components/Login.css";
class livePerformance extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      viewCompleted: false,
      loggedIn,
      filterOn: false,
      sheetList: [],
      topicList: [],
      dmsSheetList: [],
      answerList: [],
      studentList: [],
      flagList: [],
      activeStudentList: [],
      sheet_name: "",
      showAllStudent: false,
      currentStudent: "",
      questionList: [],
      isHintOpen: false,
      isHintVideoOpen: false,
      currentQuestion: "",
      isCalcOpen: false,
      isFullScreen: false,
      pollyFill: false,
      url: "https://oes.mccubeindia.com",
      url2: "https://mccubeimg.herokuapp.com/images/",
      currentSheet: "",
      liveUpdate: false,
      needHelp: false,
      needer: "1",
      password: "",
    };
  }
  componentDidMount() {
    this.sheetList();
    this.topicList();
    this.answersList();
    this.flagList();
    this.userList();
    this.List();
  }

  liveUpdate = () => {
    if (this.state.liveUpdate) {
      this.answersList();
      this.flagList();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      filterOn: true,
      currentQuestion: "",
      currentSheet: this.state.sheetList.filter(
        (item) => item.sheet_name === e.target.value
      )[0],
    });
  };

  refreshState = () => {
    this.answersList();
    this.flagList();
    this.List();
  };

  sheetList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/sheetname/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          sheetList: res.data,
          dmsSheetList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };
  updateSheet = (e) => {
    this.setState({
      currentSheet: {
        ...this.state.currentSheet,
        [e.target.name]: e.target.value,
      },
    });
  };
  saveSheet = () => {
    const id = this.state.currentSheet.id;
    const item = this.state.currentSheet;
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/sheet/sheetname/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then(() => this.sheetList())
      .catch((err) => console.log(err));
  };
  topicList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/topic/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          topicList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  answersList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/useranswers/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          answerList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  flagList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/flag/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          flagList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  userList = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/level/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          studentList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  List = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/questions/?sheet_name=${this.state.sheet_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) =>
        this.setState({ questionList: res.data, filterOn: false })
      );
  };

  updateQuestion = (e) => {
    this.setState({
      currentQuestion: {
        ...this.state.currentQuestion,
        [e.target.name]: e.target.value,
      },
    });
  };

  saveQuestion = () => {
    const id = this.state.currentQuestion.id;
    const item = this.state.currentQuestion;
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/sheet/questions/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.List());
  };

  renderQuestion = () => {
    return (
      <div className="topicsheet">
        <div class="input-group input-group-lg">
          <button
            className="remove"
            onClick={() => this.setState({ currentQuestion: "" })}
          >
            X
          </button>
          Question. {this.state.currentQuestion.question_no})
          <div className="currentQuestion.question">
            <textarea
              onChange={this.updateQuestion}
              name="question"
              style={{ border: "none" }}
              rows={~~(this.state.currentQuestion.question.length / 150) + 1}
              cols="150"
              value={this.state.currentQuestion.question
                .split("<br>")
                .map((i) => i)}
            />
            {this.state.currentQuestion.question_image === "1" ? null : this
                .state.currentQuestion.question_image === "" ? null : (
              <>
                <img
                  src={
                    this.state.url + this.state.currentQuestion.question_image
                  }
                  alt=""
                  className="qimage"
                />
                <img
                  src={
                    this.state.url2 + this.state.currentQuestion.question_image
                  }
                  alt=""
                  className="qimage"
                />
              </>
            )}
          </div>
        </div>
        <div>
          <div class="list-group">
            <div>
              (A)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_a.length / 25) + 1}
                cols="25"
                name="option_a"
                value={this.state.currentQuestion.option_a}
                className={
                  this.state.currentQuestion.correct_answer === "a"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />
              (B)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_b.length / 25) + 1}
                cols="25"
                name="option_b"
                value={this.state.currentQuestion.option_b}
                className={
                  this.state.currentQuestion.correct_answer === "b"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />
              (C)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_c.length / 25) + 1}
                cols="25"
                name="option_c"
                value={this.state.currentQuestion.option_c}
                className={
                  this.state.currentQuestion.correct_answer === "c"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />
              (D)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_d.length / 25) + 1}
                cols="25"
                name="option_d"
                value={this.state.currentQuestion.option_d}
                className={
                  this.state.currentQuestion.correct_answer === "d"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />{" "}
              Ans.{" "}
              <input
                onChange={this.updateQuestion}
                name="correct_answer"
                style={{ border: "none", width: 30 }}
                value={this.state.currentQuestion.correct_answer}
              />
              <button className="button2" onClick={() => this.saveQuestion()}>
                save changes
              </button>
            </div>

            <div className="bottombuttons">
              <div className="hint">
                {this.props.hintOn ? this.dmsHint() : null}
                <button
                  className="button2"
                  onClick={() =>
                    this.setState({ isHintOpen: !this.state.isHintOpen })
                  }
                >
                  hint
                </button>
                <Collapse isOpen={this.state.isHintOpen}>
                  <textarea
                    onChange={this.updateQuestion}
                    name="hint"
                    style={{ border: "none" }}
                    rows="10"
                    cols="150"
                    value={this.state.currentQuestion.hint
                      .split("<br>")
                      .map((i) => i)}
                  />
                  {this.state.currentQuestion.hint_image === "1" ? null : this
                      .state.currentQuestion.hint_image === "" ? null : (
                    <img
                      src={
                        this.state.url + this.state.currentQuestion.hint_image
                      }
                      alt=""
                      className="qimage"
                    />
                  )}
                </Collapse>
              </div>
              <div className="hint_video">
                {this.props.videoOn ? this.dmsVideo() : null}
                <button
                  className="button2"
                  onClick={() =>
                    this.setState({
                      isHintVideoOpen: !this.state.isHintVideoOpen,
                    })
                  }
                >
                  hint video
                </button>
              </div>
            </div>
          </div>

          <Collapse isOpen={this.state.isHintVideoOpen}>
            <div className={this.props.fullScreenOn ? "fullscreen" : "wrapper"}>
              <ReactPlayer
                playing={this.props.playOn}
                ref="player"
                controls
                url={this.state.url + this.state.currentQuestion.hint_video}
                width="100%"
                height="100%"
                className="player"
              />
            </div>
          </Collapse>
        </div>
        <button
          className="button2"
          onClick={() => this.setState({ isCalcOpen: !this.state.isCalcOpen })}
        >
          Calculator
        </button>
        question_image
        <input
          onChange={this.updateQuestion}
          name="question_image"
          value={this.state.currentQuestion.question_image}
        />
        hint_image
        <input
          onChange={this.updateQuestion}
          name="hint_image"
          value={this.state.currentQuestion.hint_image}
        />
        hint_video
        <input
          onChange={this.updateQuestion}
          name="hint_video"
          value={this.state.currentQuestion.hint_video}
        />
        {this.state.isCalcOpen ? <Calculator /> : null}
        <CSVLink
          data={this.state.questionList}
          filename={`E_${this.state.sheet_name}`}
        >
          Export
        </CSVLink>
      </div>
    );
  };
  dmsHint = () => {
    this.setState({ isHintOpen: !this.state.isHintOpen });
    this.props.changeHintOn(false);
  };
  dmsVideo = () => {
    this.setState({ isHintVideoOpen: !this.state.isHintVideoOpen });
    this.props.changeVideoOn(false);
  };

  selectQuestion = (x) => {
    if (
      (this.state.questionList.length !== 0) &
      (x <= this.state.questionList.length)
    ) {
      this.setState({
        isHintOpen: false,
        isHintVideoOpen: false,
        currentQuestion: this.state.questionList[x - 1],
      });
      this.props.changeQuestionOn(false);
      this.props.changeQuestionInput("");
    } else {
      alert("wait");
    }
  };

  renderSheets = () => {
    const newItems = this.state.sheetList;
    return newItems.map((item) => <option>{item.sheet_name}</option>);
  };

  dmsFilter = () => {
    const newitem = this.state.dmsSheetList;
    const x = this.props.sheetInput.split(" ").pop();
    const y = this.props.sheetInput
      .replace(" ", "_")
      .replace(" ", "_")
      .replace(" ", "_");
    const topics = this.state.topicList.map((item) => item.topic);
    if (
      x === "BASIC" ||
      x === "FOUNDATION" ||
      x === "MEDIUM" ||
      x === "ADVANCED"
    ) {
      const filteredItem = newitem.filter((item) => item.level === x);
      this.setState({ dmsSheetList: filteredItem });
      this.props.changeSheetInput("");
    } else if (topics.includes(x)) {
      const filteredItem = newitem.filter((item) => item.topic === x);
      this.setState({ dmsSheetList: filteredItem });
      this.props.changeSheetInput("");
    } else if (topics.includes(y)) {
      const filteredItem = newitem.filter((item) => item.topic === y);
      this.setState({ dmsSheetList: filteredItem });
      this.props.changeSheetInput("");
    } else if (
      x === "QA" ||
      x === "LR" ||
      x === "DI" ||
      x === "VA" ||
      x === "GA" ||
      x === "GK"
    ) {
      const filteredItem = newitem.filter((item) => item.subject === x);
      this.setState({ dmsSheetList: filteredItem });
      this.props.changeSheetInput("");
    } else if (x === "MAIN" || x === "PRACTICE") {
      const filteredItem = newitem.filter(
        (item) => item.sheet_type === x.toLowerCase()
      );
      this.setState({ dmsSheetList: filteredItem });
      this.props.changeSheetInput("");
    } else if (this.state.dmsSheetList.length === 1) {
      const finalItem = this.state.dmsSheetList[0].sheet_name;
      this.setState({
        sheet_name: finalItem,
        filterOn: true,
        dmsSheetList: this.state.sheetList,
        currentQuestion: "",
      });
      this.props.changeSheetOn(false);
    }
  };

  renderFilter = () => {
    return (
      <div className="pfiltersection">
        <label className="listingform__label pfilterlabel" htmlFor="topic">
          Select Sheet [ total = {this.state.dmsSheetList.length} ]
        </label>
        <select
          className="listingform__select pfilterselect"
          name="sheet_name"
          onChange={this.handleChange}
          value={this.state.sheet_name}
        >
          <option></option>
          {this.renderSheets()}
        </select>
      </div>
    );
  };

  activateStudent = (x) => {
    if (!this.state.activeStudentList.includes(x)) {
      const item = [...this.state.activeStudentList, x];
      this.setState({
        activeStudentList: item,
        currentStudent: x,
      });
      this.props.changeJoinOn(false);
      this.props.changeJoinInput("");
    }
    this.password(x);
  };

  removeFromActiveList = (y) => {
    const item = this.state.activeStudentList.filter((item) => item !== y);
    this.setState({
      activeStudentList: item,
      currentStudent:
        this.state.currentStudent === y ? "" : this.state.currentStudent,
    });
    this.props.changeRemoveOn(false);
    this.props.changeRemoveInput("");
  };

  studentList = () => {
    const joinInput = this.props.joinInput || "";
    const activeStudentList = this.state.activeStudentList;
    const newItem = this.state.studentList;
    return newItem.map((item, i) => (
      <>
        {(joinInput.replace(/[ ]/g, "") === item.user_name) &
        !activeStudentList.includes(item.user_name)
          ? this.activateStudent(item.user_name)
          : null}
        <button
          className={
            activeStudentList.includes(item.user_name)
              ? "pselectedstudents"
              : "pallstudents"
          }
          onClick={() => this.activateStudent(item.user_name)}
        >
          {i}.{item.user_name}
        </button>
      </>
    ));
  };

  dmsSelect = (x) => {
    this.setState({ currentStudent: x });
    this.props.changeSelectOn(false);
    this.props.changeSelectInput("");
  };

  renderAnswers = (y) => {
    const no_of_questions = this.state.sheetList
      .filter((item) => item.sheet_name === this.state.sheet_name)
      .map((item) => item.no_of_questions);
    const item = this.state.answerList.filter(
      (item) => item.sheet_name === this.state.sheet_name
    );
    const newItem = item.filter((item) => item.user_name === y);
    const correct = newItem
      .filter((item) => item.user_answer === item.correct_answer)
      .map((item) => item.question_no);
    const incorrect = newItem
      .filter((item) => item.user_answer !== item.correct_answer)
      .map((item) => item.question_no);
    const questions = Array(no_of_questions[0])
      .fill()
      .map((x, i) => i + 1);
    const flagItem = this.state.flagList.filter(
      (item) => item.sheet_name === this.state.sheet_name
    );
    const flagNewItem = flagItem.filter((item) => item.user_name === y);
    const flag = flagNewItem.map((item) => item.question_no);
    const removeInput = this.props.removeInput || "";
    const selectInput = this.props.selectInput || "";
    return (
      <div style={{ order: correct.length }}>
        {this.props.removeInput === y
          ? this.removeFromActiveList(y)
          : removeInput.replace(/[ ]/g, "") === y
          ? this.removeFromActiveList(y)
          : null}
        <button className="remove" onClick={() => this.removeFromActiveList(y)}>
          X
        </button>
        <button
          className="pactivestudents"
          onClick={() => this.setState({ currentStudent: y })}
        >
          {y}
        </button>
        {this.props.selectInput === y
          ? this.dmsSelect(y)
          : (selectInput.replace(/\s/g, "") === y) &
            (y !== this.state.currentStudent)
          ? this.dmsSelect(y)
          : null}
        {questions.map((item) => (
          <button
            className={
              flag.includes(item)
                ? correct.includes(item)
                  ? "pcorrect flag"
                  : incorrect.includes(item)
                  ? "pincorrect flag"
                  : "pfnormal flag"
                : correct.includes(item)
                ? "pcorrect"
                : incorrect.includes(item)
                ? "pincorrect"
                : "pnormal"
            }
            onClick={() => this.selectQuestion(item)}
          >
            {item}
          </button>
        ))}
        <button className="pcorrecttotal">{correct.length}</button>
        <button className="pincorrecttotal">{incorrect.length}</button>
        <button className="pnormaltotal">
          {no_of_questions - correct.length - incorrect.length}
        </button>
      </div>
    );
  };

  delAns = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/sheet/useranswers/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => console.log(err));
  };
  clrDbt = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/sheet/flag/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => console.log(err));
  };

  removeAllAnswers = (x) => {
    x.forEach((item) => {
      this.delAns(item.id);
    });
    this.props.changeClearInput("");
    this.props.changeClearOn(false);
    this.setState({ currentStudent: "" });
  };

  removeAllDoubts = (x) => {
    x.forEach((item) => {
      this.clrDbt(item.id);
    });
    this.props.changeClearInput("");
    this.props.changeClearOn(false);
    this.setState({ currentStudent: "" });
  };

  password = (x) => {
    const d = new Date();
    let minutes = d.getMinutes();
    var word = x;
    var sum = 0;
    word
      .toUpperCase()
      .split("")
      .forEach(function (alphabet) {
        sum += alphabet.charCodeAt(0) - 64;
      });
    var password = (sum + minutes + 1) * (sum + minutes + 1);
    this.setState({ password: password });
  };

  renderSeprateAnswers = () => {
    const item = this.state.answerList.filter(
      (item) => item.sheet_name === this.state.sheet_name
    );
    const newItem = item.filter(
      (item) => item.user_name === this.state.currentStudent
    );

    const incorrect = newItem.filter(
      (item) => item.user_answer !== item.correct_answer
    );
    return (
      <div>
        {this.state.currentStudent !== "" ? (
          <button className="pselectedstudents">
            {this.state.currentStudent} Answers
          </button>
        ) : null}
        {newItem.map((item) => (
          <button className="pallstudents" onClick={() => this.delAns(item.id)}>
            {item.question_no}
          </button>
        ))}
        {this.state.currentStudent !== "" ? (
          <>
            {" "}
            <button
              onClick={() => {
                if (window.confirm("are you sure"))
                  this.removeAllAnswers(newItem);
              }}
              className="pselectedstudents"
            >
              Remove all
            </button>
            <button
              onClick={() => this.removeAllAnswers(incorrect)}
              className="pselectedstudents"
            >
              Remove all incorrect
            </button>
          </>
        ) : null}
        {(this.state.currentStudent !== "") & (this.props.clearInput === "all")
          ? this.removeAllAnswers(newItem)
          : null}
        {(this.state.currentStudent !== "") &
        (this.props.clearInput === "incorrect")
          ? this.removeAllAnswers(incorrect)
          : null}
        <button
          onClick={() => this.password(this.state.currentStudent)}
          className="pselectedstudents"
        >
          get otp
        </button>
        {this.state.password}
      </div>
    );
  };

  renderSeprateDoubts = () => {
    const item = this.state.flagList.filter(
      (item) => item.sheet_name === this.state.sheet_name
    );
    const newItem = item.filter(
      (item) => item.user_name === this.state.currentStudent
    );
    return (
      <div>
        {this.state.currentStudent !== "" ? (
          <button className="pselectedstudents">
            {this.state.currentStudent} Doubts
          </button>
        ) : null}
        {newItem.map((item) => (
          <button className="pallstudents" onClick={() => this.clrDbt(item.id)}>
            {item.question_no}
          </button>
        ))}
        {this.state.currentStudent !== "" ? (
          <button
            onClick={() => {
              if (window.confirm("are you sure")) this.removeAllDoubts(newItem);
            }}
            className="pselectedstudents"
          >
            Remove all
          </button>
        ) : null}
        {(this.state.currentStudent !== "") &
        (this.props.clearInput === "doubt")
          ? this.removeAllDoubts(newItem)
          : null}
      </div>
    );
  };
  textToSpeech = (x) => {
    const speech = new Speech();
    speech.setLanguage("en-IN");
    speech.speak({
      text: x,
    });
  };
  renderActiveStudents = () => {
    const activeStudentList = this.state.activeStudentList;
    return activeStudentList.map((item) => this.renderAnswers(item));
  };

  render() {
    const liveUpdate = this.state.liveUpdate ? "Off" : "On";
    if (this.props.newuser !== "yogesh") {
      this.props.history.push("logout");
    }
    return (
      <div>
        {this.state.filterOn ? this.List() : null}
        <button
          className="pactivestudents"
          onClick={() => this.props.changeShowOn(!this.props.showOn)}
        >
          {this.props.showOn ? "hide all" : "show all"}
        </button>
        <button
          onClick={() => this.props.history.push("livetestperformance")}
          className="livetestpaper"
        >
          Live Test
        </button>
        <button
          onClick={() => this.setState({ liveUpdate: !this.state.liveUpdate })}
          className="livetestpaper"
        >
          Turn {liveUpdate} Live Update
        </button>
        <button onClick={() => this.refreshState()} className="livetestpaper">
          Refresh
        </button>

        {/* <SpeechToTextPollyfill /> */}
        <SpeechToText />
        <SmartCalculator />
        <Vocab />
        <div className={this.props.showOn ? null : "showAllStudent"}>
          {this.studentList()}
        </div>
        <br />
        <div>{this.renderFilter()}</div>
        {this.props.sheetOn ? this.dmsFilter() : null}
        <br />
        <div className="renderActiveStudents">
          {this.renderActiveStudents()}
        </div>
        <div>{this.renderSeprateAnswers()}</div>
        <div>{this.renderSeprateDoubts()}</div>
        {this.props.questionOn &
        (this.props.questionInput !== "") &
        (this.state.questionList.length !== 0)
          ? this.selectQuestion(this.props.questionInput)
          : null}
        {this.state.currentQuestion.length !== 0 ? this.renderQuestion() : null}
        {this.state.currentSheet !== "" ? (
          <div>
            <label>Number of questions</label>
            <input
              onChange={this.updateSheet}
              name="no_of_questions"
              value={this.state.currentSheet.no_of_questions}
            />
            <button onClick={() => this.saveSheet()}>save changes</button>
          </div>
        ) : null}

        <CSVLink
          data={this.state.questionList}
          filename={`E_SHEET_ALL_QUESTIONS`}
        >
          Export all questions
        </CSVLink>
        <br></br>
        <CSVLink data={this.state.sheetList} filename={`E_SHEET_LIST`}>
          Export all sheets name
        </CSVLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
    clearInput: state.clearInput,
    selectInput: state.selectInput,
    joinInput: state.joinInput,
    removeInput: state.removeInput,
    sheetInput: state.sheetInput,
    sheetOn: state.sheetOn,
    questionInput: state.questionInput,
    questionOn: state.questionOn,
    hintOn: state.hintOn,
    videoOn: state.videoOn,
    fullScreenOn: state.fullScreenOn,
    playOn: state.playOn,
    showOn: state.showOn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeClearOn: (clearOn) => {
      dispatch(changeClearOn(clearOn));
    },
    changeClearInput: (clearInput) => {
      dispatch(changeClearInput(clearInput));
    },
    changeSelectOn: (selectOn) => {
      dispatch(changeSelectOn(selectOn));
    },
    changeSelectInput: (selectInput) => {
      dispatch(changeSelectInput(selectInput));
    },
    changeJoinOn: (joinOn) => {
      dispatch(changeJoinOn(joinOn));
    },
    changeJoinInput: (joinInput) => {
      dispatch(changeJoinInput(joinInput));
    },
    changeRemoveOn: (removeOn) => {
      dispatch(changeRemoveOn(removeOn));
    },
    changeRemoveInput: (removeInput) => {
      dispatch(changeRemoveInput(removeInput));
    },
    changeSheetOn: (sheetOn) => {
      dispatch(changeSheetOn(sheetOn));
    },
    changeSheetInput: (sheetInput) => {
      dispatch(changeSheetInput(sheetInput));
    },
    changeQuestionOn: (questionOn) => {
      dispatch(changeQuestionOn(questionOn));
    },
    changeQuestionInput: (questionInput) => {
      dispatch(changeQuestionInput(questionInput));
    },
    changeHintOn: (hintOn) => {
      dispatch(changeHintOn(hintOn));
    },
    changeVideoOn: (videoOn) => {
      dispatch(changeVideoOn(videoOn));
    },
    changeFullScreenOn: (fullScreenOn) => {
      dispatch(changeFullScreenOn(fullScreenOn));
    },
    changePlayOn: (playOn) => {
      dispatch(changePlayOn(playOn));
    },
    changeShowOn: (showOn) => {
      dispatch(changeShowOn(showOn));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(livePerformance);
