import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.activeItem,
    };
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    const activeItem = { ...this.state.activeItem, [name]: value };
    this.setState({ activeItem });
  };
  render() {
    const { toggle, onSave } = this.props;
    return (
      <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}> Todo Item </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={this.state.activeItem.name}
                onChange={this.handleChange}
                placeholder="Enter Name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="number">Number</Label>
              <Input
                type="text"
                name="number"
                value={this.state.activeItem.number}
                onChange={this.handleChange}
                placeholder="Enter Number"
              />
            </FormGroup>
            <FormGroup>
              <Label for="college">College</Label>
              <Input
                type="text"
                name="college"
                value={this.state.activeItem.college}
                onChange={this.handleChange}
                placeholder="Enter College"
              />
            </FormGroup>
            <FormGroup>
              <Label for="stream">Stream</Label>
              <Input
                type="text"
                name="stream"
                value={this.state.activeItem.stream}
                onChange={this.handleChange}
                placeholder="Enter Stream"
              />
            </FormGroup>
            <FormGroup>
              <Label for="year">Year</Label>
              <Input
                type="text"
                name="year"
                value={this.state.activeItem.year}
                onChange={this.handleChange}
                placeholder="Enter Year"
              />
            </FormGroup>
            <FormGroup>
              <Label for="semester">Semester</Label>
              <Input
                type="text"
                name="semester"
                value={this.state.activeItem.semester}
                onChange={this.handleChange}
                placeholder="Enter semester"
              />
            </FormGroup>
            <FormGroup>
              <Label for="section">Section</Label>
              <Input
                type="text"
                name="section"
                value={this.state.activeItem.section}
                onChange={this.handleChange}
                placeholder="Enter Section"
              />
            </FormGroup>
            <FormGroup>
              <Label for="reference">Reference</Label>
              <Input
                type="text"
                name="reference"
                value={this.state.activeItem.reference}
                onChange={this.handleChange}
                placeholder="Enter Reference"
              />
            </FormGroup>
            <FormGroup check>
              <Label for="called">
                <Input
                  type="checkbox"
                  name="called"
                  checked={this.state.activeItem.called}
                  onChange={this.handleChange}
                />
                Interested
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label for="switchoff">
                <Input
                  type="checkbox"
                  name="swithoff"
                  checked={this.state.activeItem.swithoff}
                  onChange={this.handleChange}
                />
                Switch Off
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label for="notanswered">
                <Input
                  type="checkbox"
                  name="notanswered"
                  checked={this.state.activeItem.notanswered}
                  onChange={this.handleChange}
                />
                Not Answered
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label for="nocoverage">
                <Input
                  type="checkbox"
                  name="nocoverage"
                  checked={this.state.activeItem.nocoverage}
                  onChange={this.handleChange}
                />
                No Coverage
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label for="notinterested">
                <Input
                  type="checkbox"
                  name="notinterested"
                  checked={this.state.activeItem.notinterested}
                  onChange={this.handleChange}
                />
                Not Interested
              </Label>
            </FormGroup>
            <FormGroup>
              <Label for="calledtime">Called Time</Label>
              <Input
                type="text"
                name="calledtime"
                value={this.state.activeItem.calledtime}
                onChange={this.handleChange}
                placeholder="Enter Called Time"
              />
            </FormGroup>
            <FormGroup>
              <Label for="nextcalltime">Next Call Time</Label>
              <Input
                type="text"
                name="nextcalltime"
                value={this.state.activeItem.nextcalltime}
                onChange={this.handleChange}
                placeholder="Enter Next Call Time"
              />
            </FormGroup>
            <FormGroup>
              <Label for="visittime">Visit Time</Label>
              <Input
                type="text"
                name="visittime"
                value={this.state.activeItem.visittime}
                onChange={this.handleChange}
                placeholder="Enter Visit Time"
              />
            </FormGroup>
            <FormGroup>
              <Label for="demotime">Demo Time</Label>
              <Input
                type="text"
                name="demotime"
                value={this.state.activeItem.demotime}
                onChange={this.handleChange}
                placeholder="Enter Demo Time"
              />
            </FormGroup>
            <FormGroup>
              <Label for="remark">Remark</Label>
              <Input
                type="text"
                name="remark"
                value={this.state.activeItem.remark}
                onChange={this.handleChange}
                placeholder="Enter Remark"
              />
            </FormGroup>
            <FormGroup check>
              <Label for="converted">
                <Input
                  type="checkbox"
                  name="converted"
                  checked={this.state.activeItem.converted}
                  onChange={this.handleChange}
                />
                Converted
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => onSave(this.state.activeItem)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
