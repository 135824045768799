import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "../components/Login.css";

class TestAnalysis extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      testList: [],
      completedTestList: [],
      testPaper: "",
      loggedIn,
    };
  }
  componentDidMount() {
    this.testList();
    this.completedTestList();
  }

  testList = () => {
    axios
      .get(`https://backend.mccubeindia.com/testpaper/testpaper/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ testList: res.data }))
      .catch((err) => console.log(err));
  };

  completedTestList = () => {
    axios
      .get(`https://backend.mccubeindia.com/testpaper/userresult/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ completedTestList: res.data }))
      .catch((err) => console.log(err));
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  renderTestNames = () => {
    const newItems = this.state.testList;
    return newItems.map((item) => <option>{item.test_name}</option>);
  };

  renderFilter = () => {
    return (
      <div className="listingform__section pfiltersection">
        <label className="listingform__label pfilterlabel" htmlFor="topic">
          Select Test Paper
        </label>
        <select
          className="listingform__select pfilterselect"
          name="testPaper"
          onChange={this.handleChange}
          value={this.state.testPaper}
        >
          <option></option>
          {this.renderTestNames()}
        </select>
      </div>
    );
  };

  renderTestScores = () => {
    const completedTestList = this.state.completedTestList.filter(
      (item) => item.test_name === this.state.testPaper
    );
    const newList = completedTestList.sort((a, b) => b.score - a.score);
    return newList.map((item, index) => (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{item.user_name}</td>
        <td>{item.score}</td>
      </tr>
    ));
  };
  render() {
    if (this.props.newuser !== "yogesh") {
      this.props.history.push("logout");
    }
    return (
      <div>
        {this.renderFilter()}
        <br />
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Rank</th>
              <th scope="col">Name</th>
              <th scope="col">Score</th>
            </tr>
          </thead>
          <tbody>
            {this.state.testPaper !== "" ? this.renderTestScores() : null}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
  };
};

export default connect(mapStateToProps)(TestAnalysis);
