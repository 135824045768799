import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "./TopicSheet.css";

class StudentSheets extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      level: "",
      subject: "",
      topic: "",
      sheet_type: "",
      sheetList: [],
      completedSheetList: [],
      loggedIn,
      student: "",
      studentList: [],
    };
  }
  componentDidMount() {
    this.sheetList();
    this.completedSheetList();
    this.userList();
  }

  sheetList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/sheetname/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ sheetList: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  completedSheetList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/index/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ completedSheetList: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  userList = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/level/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          studentList: res.data,
          filterOn: false,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitSheet = (x) => {
    const item = {
      user_name: this.state.student,
      sheet_name: x,
    };
    axios
      .post(`https://backend.mccubeindia.com/sheet/index/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.completedSheetList())
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  renderStudentNames = () => {
    const newItems = this.state.studentList;
    return newItems.map((item) => <option>{item.user_name}</option>);
  };

  renderFilter = () => {
    return (
      <div className="listingform__section pfiltersection">
        <label className="listingform__label pfilterlabel" htmlFor="topic">
          Select Student
        </label>
        <select
          className="listingform__select pfilterselect"
          name="student"
          onChange={this.handleChange}
          value={this.state.student}
        >
          <option></option>
          {this.renderStudentNames()}
        </select>
      </div>
    );
  };

  renderSheets = () => {
    const submittedSheet = this.state.completedSheetList
      .filter((item) => item.user_name === this.state.student)
      .map((item) => item.sheet_name);
    const newItems = this.state.sheetList;
    return newItems.map((item) => (
      <tr>
        <th scope="row">{item.level}</th>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {item.subject}
        </td>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {item.topic}
        </td>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {item.sheet_type}
        </td>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {!submittedSheet.includes(item.sheet_name) ? (
            <button
              type="button"
              className="btn btn-outline-success startbutton"
              onClick={() => this.submitSheet(item.sheet_name)}
            >
              Submit
            </button>
          ) : null}
        </td>
      </tr>
    ));
  };

  render() {
    if (this.props.newuser !== "yogesh") {
      this.props.history.push("logout");
    }
    return (
      <div>
        <div>{this.renderFilter()}</div>
        <br />
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">level</th>
              <th scope="col">subject</th>
              <th scope="col">topic</th>
              <th scope="col">sheet type</th>
              <th scope="col">action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.student !== "" ? this.renderSheets() : null}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
  };
};

export default connect(mapStateToProps)(StudentSheets);
