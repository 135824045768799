import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

export default class MCCUBEModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.activeItem,
    };
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    const activeItem = { ...this.state.activeItem, [name]: value };
    this.setState({ activeItem });
  };
  render() {
    const { toggle, onSave } = this.props;
    return (
      <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}> MCCUBE</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                type="text"
                name="name"
                value={this.state.activeItem.name}
                onChange={this.handleChange}
                placeholder="Enter Name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="number">Number</Label>
              <Input
                type="text"
                name="number"
                value={this.state.activeItem.number}
                onChange={this.handleChange}
                placeholder="Enter Number"
              />
            </FormGroup>
            <FormGroup>
              <Label for="fathername">Father Name</Label>
              <Input
                type="text"
                name="fathername"
                value={this.state.activeItem.fathername}
                onChange={this.handleChange}
                placeholder="Enter Father Name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="mothername">Mother Name</Label>
              <Input
                type="text"
                name="mothername"
                value={this.state.activeItem.mothername}
                onChange={this.handleChange}
                placeholder="Enter Mother Name"
              />
            </FormGroup>
            <FormGroup>
              <Label for="college">College</Label>
              <Input
                type="text"
                name="college"
                value={this.state.activeItem.college}
                onChange={this.handleChange}
                placeholder="Enter College"
              />
            </FormGroup>
            <FormGroup>
              <Label for="stream">Stream</Label>
              <Input
                type="text"
                name="stream"
                value={this.state.activeItem.stream}
                onChange={this.handleChange}
                placeholder="Enter Stream"
              />
            </FormGroup>
            <FormGroup>
              <Label for="year">Year</Label>
              <Input
                type="text"
                name="year"
                value={this.state.activeItem.year}
                onChange={this.handleChange}
                placeholder="Enter Year"
              />
            </FormGroup>
            <FormGroup>
              <Label for="semester">Semester</Label>
              <Input
                type="text"
                name="semester"
                value={this.state.activeItem.semester}
                onChange={this.handleChange}
                placeholder="Enter semester"
              />
            </FormGroup>
            <FormGroup>
              <Label for="section">Section</Label>
              <Input
                type="text"
                name="section"
                value={this.state.activeItem.section}
                onChange={this.handleChange}
                placeholder="Enter Section"
              />
            </FormGroup>
            <FormGroup>
              <Label for="reference">Reference</Label>
              <Input
                type="text"
                name="reference"
                value={this.state.activeItem.reference}
                onChange={this.handleChange}
                placeholder="Enter Reference"
              />
            </FormGroup>

            <FormGroup>
              <Label for="tenthpercentage">Tenth Percentage</Label>
              <Input
                type="text"
                name="tenthpercentage"
                value={this.state.activeItem.tenthpercentage}
                onChange={this.handleChange}
                placeholder="Enter Tenth Percentage"
              />
            </FormGroup>
            <FormGroup>
              <Label for="twelthpercentage">Twelth Percentage</Label>
              <Input
                type="text"
                name="twelthpercentage"
                value={this.state.activeItem.twelthpercentage}
                onChange={this.handleChange}
                placeholder="Enter Twelth Percentage"
              />
            </FormGroup>
            <FormGroup>
              <Label for="graduationpercentage">Graduation Percentage</Label>
              <Input
                type="text"
                name="graduationpercentage"
                value={this.state.activeItem.graduationpercentage}
                onChange={this.handleChange}
                placeholder="Enter Graduation Percentage"
              />
            </FormGroup>
            <FormGroup>
              <Label for="course">Course</Label>
              <Input
                type="text"
                name="course"
                value={this.state.activeItem.course}
                onChange={this.handleChange}
                placeholder="Enter Course"
              />
            </FormGroup>
            <FormGroup>
              <Label for="datejoined">Date Joined</Label>
              <Input
                type="text"
                name="datejoined"
                value={this.state.activeItem.datejoined}
                onChange={this.handleChange}
                placeholder="Enter datejoined"
              />
            </FormGroup>
            <FormGroup>
              <Label for="monthjoined">Month Joined</Label>
              <Input
                type="text"
                name="monthjoined"
                value={this.state.activeItem.monthjoined}
                onChange={this.handleChange}
                placeholder="Enter Month Joined"
              />
            </FormGroup>
            <FormGroup>
              <Label for="totalfees">Total Fees</Label>
              <Input
                type="text"
                name="totalfees"
                value={this.state.activeItem.totalfees}
                onChange={this.handleChange}
                placeholder="Enter Total Fees"
              />
            </FormGroup>
            <FormGroup>
              <Label for="feespaid">Fees Paid</Label>
              <Input
                type="text"
                name="feespaid"
                value={this.state.activeItem.feespaid}
                onChange={this.handleChange}
                placeholder="Enter Fees Paid"
              />
            </FormGroup>
            <FormGroup>
              <Label for="feesremaining">Rees Remaining</Label>
              <Input
                type="text"
                name="feesremaining"
                value={this.state.activeItem.feesremaining}
                onChange={this.handleChange}
                placeholder="Enter Fees Remaining"
              />
            </FormGroup>
            <FormGroup>
              <Label for="nextfeesdate">Next Fees Date</Label>
              <Input
                type="text"
                name="nextfeesdate"
                value={this.state.activeItem.nextfeesdate}
                onChange={this.handleChange}
                placeholder="Enter Next Fees Date"
              />
            </FormGroup>
            <FormGroup>
              <Label for="nextfeesmonth">Next Fees Month</Label>
              <Input
                type="text"
                name="nextfeesmonth"
                value={this.state.activeItem.nextfeesmonth}
                onChange={this.handleChange}
                placeholder="Enter Next Fees Month"
              />
            </FormGroup>

            <FormGroup check>
              <Label for="feescompleted">
                <Input
                  type="checkbox"
                  name="feescompleted"
                  checked={this.state.activeItem.feescompleted}
                  onChange={this.handleChange}
                />
                Fees Completed
              </Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => onSave(this.state.activeItem)}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
