import React, { Component } from "react";
import Modal from "./Modal";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class AllStudents extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      viewCompleted: false,
      loggedIn,
      filterOn: true,
      name: "",
      number: "",
      college: "",
      reference: "",
      date: "",
      month: "",
      year: "",
      semester: "",
      section: "",
      showRemark: false,
      activeItem: {
        name: "",
        number: "",
        college: "",
        stream: "",
        year: "",
        semester: "",
        section: "",
        reference: "",
        called: false,
        swithoff: false,
        notanswered: false,
        nocoverage: false,
        notinterested: false,
        calledtime: "",
        nextcalldate: "",
        nextcallmonth: "",
        nextcalltime: "",
        ampm: "",
        visitdate: "",
        visitmonth: "",
        visittime: "",
        visitampm: "",
        demodate: "",
        deommonth: "",
        demotime: "",
        demoampm: "",
        remark: "",
        converted: false,
      },
      todoList: [],
    };
  }
  componentDidMount() {
    this.refreshList();
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, filterOn: true });
  };

  refreshList = () => {
    const currentuser = this.props.newuser;
    const fulldatetime = new Date();
    const currentdate = fulldatetime.getDate();
    const currentmonth = fulldatetime.getMonth() + 1;
    const currentyear = fulldatetime.getFullYear();
    const user =
      this.state.reference === "" ? currentuser : this.state.reference;
    const date = this.state.date === "" ? currentdate : this.state.date;
    const month = this.state.month === "" ? currentmonth : this.state.month;
    const year = this.state.year === "" ? currentyear : this.state.year;
    const fulldate = user + "/" + date + "/" + month + "/" + year;
    axios
      .get(
        `https://backend.mccubeindia.com/user/allstudent/?reference=${fulldate}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ todoList: res.data }))
      .catch((err) => console.log(err));
  };
  renderFilter = () => {
    return (
      <div>
        <div className="row">
          <div className="filterdev">
            <input
              className="filterinput"
              name="reference"
              onChange={this.handleChange}
              value={this.state.reference}
              placeholder="reference"
            />
          </div>
          <div className="filterdev">
            <input
              className="filterinput"
              name="date"
              onChange={this.handleChange}
              value={this.state.date}
              placeholder="date"
            />
          </div>
        </div>
        <div className="row">
          <div className="filterdev">
            <input
              className="filterinput"
              name="month"
              onChange={this.handleChange}
              value={this.state.month}
              placeholder="month"
            />
          </div>
          <div className="filterdev">
            <input
              className="filterinput"
              name="year"
              onChange={this.handleChange}
              value={this.state.year}
              placeholder="year"
            />
          </div>
        </div>
        <button
          onClick={() => this.refreshList()}
          className="btn btn-secondary mr-2"
        >
          get
        </button>
      </div>
    );
  };
  renderItems = () => {
    const newItems = this.state.todoList;
    return newItems.map((item) => (
      <tr
        className={
          item.swithoff
            ? "allstudenttrCB"
            : item.notanswered
            ? "allstudenttrCB"
            : item.nocoverage
            ? "allstudenttrCB"
            : item.notinterested
            ? "allstudenttrNI"
            : item.converted
            ? "allstudenttrConverted"
            : item.called
            ? "allstudenttrI"
            : null
        }
      >
        <th scope="row">
          <p className="namelist">{item.name}</p>
        </th>
        <td>
          <p className="namelist">{item.number}</p>
        </td>
        {this.state.showRemark ? (
          <td>
            <p className="namelist">
              {item.swithoff
                ? "#sw "
                : item.notanswered
                ? "#na "
                : item.nocoverage
                ? "#nc "
                : ""}
              {item.remark}
            </p>
          </td>
        ) : null}
        <td>
          <button
            onClick={() => this.editItem(item)}
            className="btn btn-secondary mr-2"
          >
            {" "}
            View{" "}
          </button>
        </td>
      </tr>
    ));
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  handleSubmit = (item) => {
    this.toggle();
    if (item.id) {
      axios
        .put(
          `https://backend.mccubeindia.com/user/allstudent/${item.id}/`,
          item,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => this.refreshList());
      return;
    } else {
      axios
        .post(`https://backend.mccubeindia.com/user/allstudent/`, item, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => this.refreshList());
    }
  };
  handleDelete = (item) => {
    axios
      .delete(`https://backend.mccubeindia.com/user/allstudent/${item.id}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.refreshList());
  };
  createItem = () => {
    const item = {
      name: "",
      number: "",
      college: "",
      stream: "",
      year: "",
      semester: "",
      section: "",
      reference: "",
      called: false,
      swithoff: false,
      notanswered: false,
      nocoverage: false,
      notinterested: false,
      calledtime: "",
      nextcalldate: "",
      nextcallmonth: "",
      nextcalltime: "",
      ampm: "",
      visitdate: "",
      visitmonth: "",
      visittime: "",
      visitampm: "",
      demodate: "",
      deommonth: "",
      demotime: "",
      demoampm: "",
      remark: "",
      converted: false,
    };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };
  editItem = (item) => {
    this.setState({ activeItem: item, modal: !this.state.modal });
  };
  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/logout" />;
    } else
      return (
        <div>
          <main className="content">
            <div className="row ">
              <div className="col-md-6 col-sm-10 mx-auto p-0">
                <div className="card p-3">
                  {this.renderFilter()}
                  <div className="">
                    <button
                      onClick={this.createItem}
                      className="btn btn-primary"
                    >
                      Add New
                    </button>
                    <button
                      onClick={() =>
                        this.setState({ showRemark: !this.state.showRemark })
                      }
                      className="btn btn-primary"
                    >
                      {this.state.showRemark ? "hide" : "show"} remark
                    </button>
                  </div>
                  <ul className="list-group list-group-flush">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">
                            <p className="namelist">Name</p>
                          </th>
                          <th scope="col">
                            <p className="numberlist">Number</p>
                          </th>
                          {this.state.showRemark ? (
                            <th scope="col">
                              <p className="numberlist">Remark</p>
                            </th>
                          ) : null}
                          <th scope="col">
                            <p className="action">Action</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.renderItems()}</tbody>
                    </table>
                  </ul>
                </div>
              </div>
            </div>
            {this.state.modal ? (
              <Modal
                activeItem={this.state.activeItem}
                toggle={this.toggle}
                onSave={this.handleSubmit}
              />
            ) : null}
          </main>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
  };
};

export default connect(mapStateToProps)(AllStudents);
