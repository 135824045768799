import React, { Component } from "react";
import Speech from "speak-tts";
import { changeCalculatorInput, changeAnswerOn } from "../actions/action";
import { connect } from "react-redux";

class SmartCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "",
      testing: "",
      speak: false,
    };
  }
  result = () => {
    const equation = this.textToEquation(this.props.calculatorInput);
    this.setState({ testing: equation });
    try {
      this.setState({
        result: eval(equation),
        speak: true,
      });
    } catch (e) {
      this.setState({
        result: "error",
      });
    }
    setTimeout(() => {
      this.textToSpeech(`equals to "${this.state.result}"`);
    }, 1000);
    this.props.changeAnswerOn(false);
    return equation;
  };

  textToSpeech = (x) => {
    const speech = new Speech();
    if (this.state.speak === true) {
      speech.speak({
        text: x,
      });
      this.setState({ speak: false });
    }
  };

  textToEquation = (x) => {
    const input = x.includes("whole" || "hole") ? "((" + x : "(" + x + ")";
    const stepOne = input
      .replace(/square root/g, "@")
      .replace(/cube root/g, "#")
      .replace(/root/g, "@")
      .replace(/route/g, "@")
      .replace(/fruit/g, "@")
      .replace(/square/g, "$2")
      .replace(/cube/g, "$3")
      .replace(/power/g, "$")
      .replace(/divided bye/g, "/")
      .replace(/divided by/g, "/")
      .replace(/bye/g, "/")
      .replace(/by/g, "/")
      .replace(/bi/g, "/")
      .replace(/whole/g, "))")
      .replace(/hole/g, "))")
      .replace(/percentage/g, "/100")
      .replace(/percent/g, "/100")
      .replace(/%/g, "/100")
      .replace(/upon/g, "/")
      .replace(/plus/g, "+")
      .replace(/minus/g, "-")
      .replace(/into/g, "*")
      .replace(/multiply/g, "*")
      .replace(/multiplied/g, "*")
      .replace(/x/g, "*")
      .replace(/tu/g, "2")
      .replace(/bracket close/g, "))")
      .replace(/bracket/g, "((")
      .replace(/[+]/g, ")+(")
      .replace(/[-]/g, ")-(")
      .replace(/[*]/g, ")*(")
      .replace(/[/]/g, ")/(");
    //.replace(update me if needed)
    const stepTwo = stepOne.replace(/[^\d*+@#$^&()/{}.-]/g, "");
    const stepThree =
      stepTwo.lastIndexOf("@") > stepTwo.lastIndexOf("+") ||
      stepTwo.lastIndexOf("@") > stepTwo.lastIndexOf("-") ||
      stepTwo.lastIndexOf("@") > stepTwo.lastIndexOf("*") ||
      stepTwo.lastIndexOf("@") > stepTwo.lastIndexOf("/")
        ? stepTwo + ")"
        : stepTwo;
    const stepFour =
      stepThree.lastIndexOf("#") > stepThree.lastIndexOf("+") ||
      stepThree.lastIndexOf("#") > stepThree.lastIndexOf("-") ||
      stepThree.lastIndexOf("#") > stepThree.lastIndexOf("*") ||
      stepThree.lastIndexOf("#") > stepThree.lastIndexOf("/")
        ? stepThree + ")"
        : stepThree;
    const stepFive =
      (stepFour.indexOf("@") !== -1) &
      (stepFour.indexOf("@") < stepFour.indexOf("+"))
        ? stepFour.replace(")+", "))+(")
        : (stepFour.indexOf("@") !== -1) &
          (stepFour.indexOf("@") < stepFour.indexOf("-"))
        ? stepFour.replace(")-", "))-(")
        : (stepFour.indexOf("@") !== -1) &
          (stepFour.indexOf("@") < stepFour.indexOf("*"))
        ? stepFour.replace(")*", "))*(")
        : (stepFour.indexOf("@") !== -1) &
          (stepFour.indexOf("@") < stepFour.indexOf("/"))
        ? stepFour.replace(")/", "))/(")
        : stepFour;
    const stepSix =
      (stepFour.indexOf("#") !== -1) &
      (stepFive.indexOf("#") < stepFive.indexOf("+"))
        ? stepFour.replace(")+", "))+")
        : (stepFour.indexOf("#") !== -1) &
          (stepFive.indexOf("") < stepFive.indexOf("-"))
        ? stepFour.replace(")-", "))-")
        : (stepFour.indexOf("#") !== -1) &
          (stepFive.indexOf("#") < stepFive.indexOf("*"))
        ? stepFour.replace(")*", "))*")
        : (stepFour.indexOf("#") !== -1) &
          (stepFive.indexOf("#") < stepFive.indexOf("/"))
        ? stepFour.replace(")/", "))/")
        : stepFive;
    const output = stepSix
      .replace(/[$]/g, "**")
      .replace(/@/g, "Math.sqrt(")
      .replace(/#/g, "Math.cbrt(");
    return output;
  };
  render() {
    return (
      <div>
        {this.props.answerOn ? this.result() : null}
        <input
          className="speechinput1"
          value={`${this.state.testing} = ${this.state.result}`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    calculatorInput: state.calculatorInput,
    answerOn: state.answerOn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCalculatorInput: (calculatorInput) => {
      dispatch(changeCalculatorInput(calculatorInput));
    },
    changeAnswerOn: (answerOn) => {
      dispatch(changeAnswerOn(answerOn));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartCalculator);
