import React, { Component } from "react";
import axios from "axios";
import Speech from "speak-tts";

export default class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentList: [],
      teamAList: [],
      teamBList: [],
      showOn: true,
      teamA: true,
      sheetList: [],
      sheet_name: "",
      answerList: [],
      activeUserList: [],
      announcement: "team",
      isCheating: false,
      cheater: "1",
    };
  }

  componentDidMount() {
    this.userList();
    this.sheetList();
    setInterval(() => {
      this.liveUpdate();
    }, 25000);
    setInterval(() => {
      axios
        .get(
          "https://backend.mccubeindia.com/user/activeuser/?user_name=hint",
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) =>
          this.setState({ cheater: res.data[0].sheet_name, isCheating: true })
        )
        .then(() => {
          const item = {
            user_name: "hint",
            sheet_name: "1",
          };
          axios.put(
            `https://backend.mccubeindia.com/user/activeuser/121/`,
            item,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          );
        });
    }, 5000);
  }

  startTest = () => {
    const timeLimitPerQuestion =
      this.state.sheetList.filter(
        (i) => i.sheet_name === this.state.sheet_name
      )[0].description || 10;
    const totalQuestion =
      this.state.sheetList.filter(
        (i) => i.sheet_name === this.state.sheet_name
      )[0].no_of_questions || 40;
    const timeLimit = timeLimitPerQuestion * totalQuestion + totalQuestion * 3;
    setTimeout(() => {
      if (this.teamAtotal() > this.teamBtotal()) {
        this.textToSpeech("team A is winner");
      } else if (this.teamBtotal() > this.teamAtotal()) {
        this.textToSpeech("team B is winner");
      } else {
        this.textToSpeech("scores are equal");
      }
    }, timeLimit * 1000);
    setInterval(() => {
      this.announcement();
    }, 30000);
  };

  announcement = () => {
    if (this.state.announcement === "team") {
      if (this.teamAtotal() > this.teamBtotal()) {
        this.textToSpeech("team A is leading");
      } else if (this.teamBtotal() > this.teamAtotal()) {
        this.textToSpeech("team B is leading");
      } else {
        this.textToSpeech("both team is doing good");
      }
      this.setState({ announcement: "highestA" });
    } else if (this.state.announcement === "highestA") {
      const topscore = this.state.teamAList
        .map((item) => this.studentTotal(item))
        .reverse()[0];
      const first = this.state.teamAList.filter(
        (item) => this.studentTotal(item) === topscore
      )[0];
      this.textToSpeech(`good going ${first}`);
      this.setState({ announcement: "lowestB" });
    } else if (this.state.announcement === "lowestB") {
      const lowscore = this.state.teamBList
        .map((item) => this.studentTotal(item))
        .sort()[0];
      const last = this.state.teamBList.filter(
        (item) => this.studentTotal(item) === lowscore
      )[0];
      this.textToSpeech(`${last} don't worry be happy`);
      this.setState({ announcement: "highestB" });
    } else if (this.state.announcement === "highestB") {
      const topscore = this.state.teamBList
        .map((item) => this.studentTotal(item))
        .reverse()[0];
      const first = this.state.teamBList.filter(
        (item) => this.studentTotal(item) === topscore
      )[0];
      this.textToSpeech(`good going ${first}`);
      this.setState({ announcement: "lowestA" });
    } else if (this.state.announcement === "lowestA") {
      const lowscore = this.state.teamAList
        .map((item) => this.studentTotal(item))
        .sort()[0];
      const last = this.state.teamAList.filter(
        (item) => this.studentTotal(item) === lowscore
      )[0];
      this.textToSpeech(`${last} don't worry be happy`);
      this.setState({ announcement: "team" });
    }
  };

  textToSpeech = (x) => {
    const speech = new Speech();
    speech.setLanguage("en-IN");
    speech.speak({
      text: x,
      listeners: {
        onend: () => {
          this.setState({ isCheating: false });
        },
      },
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  liveUpdate = () => {
    if (this.state.sheet_name !== "") {
      this.answersList();
    }
  };
  userList = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/level/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          studentList: res.data,
        })
      );
  };

  activeUserList = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/user/activeuser/?sheet_name=${this.state.sheet_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) =>
        this.setState({
          activeUserList: res.data,
        })
      )
      .then(() =>
        this.state.activeUserList.map((item) =>
          this.activateStudent(item.user_name)
        )
      );
  };

  sheetList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/sheetname/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          sheetList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  answersList = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/useranswers/?sheet_name=${this.state.sheet_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) =>
        this.setState({
          answerList: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  delAns = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/sheet/useranswers/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => console.log(err));
  };
  removeAllAnswers = (x) => {
    const ansList = this.state.answerList.filter(
      (item) => item.user_name === x
    );
    ansList.forEach((item) => {
      this.delAns(item.id);
    });
  };
  renderFilter = () => {
    return (
      <div className="pfiltersection">
        <label className="listingform__label pfilterlabel" htmlFor="topic">
          Select Quiz
        </label>
        <select
          className="listingform__select pfilterselect"
          name="sheet_name"
          onChange={this.handleChange}
          value={this.state.sheet_name}
        >
          <option></option>
          {this.renderSheets()}
        </select>
      </div>
    );
  };
  renderSheets = () => {
    const newItems = this.state.sheetList.filter(
      (item) => item.published === true
    );
    return newItems.map((item) => <option>{item.sheet_name}</option>);
  };
  studentList = () => {
    const teamAList = this.state.teamAList;
    const teamBList = this.state.teamBList;
    const newItem = this.state.studentList
      .filter((item) => !teamAList.includes(item.user_name))
      .filter((item) => !teamBList.includes(item.user_name));
    return newItem.map((item) => (
      <button
        style={{ backgroundColor: "black", color: "white", borderRadius: 10 }}
        onClick={() => this.activateStudent(item.user_name)}
      >
        {item.user_name}
      </button>
    ));
  };
  activateStudent = (x) => {
    if (!this.state.teamAList.includes(x) & !this.state.teamBList.includes(x)) {
      if (this.state.teamA) {
        const itemA = [...this.state.teamAList, x];
        this.setState({ teamAList: itemA, teamA: false });
      } else {
        const itemB = [...this.state.teamBList, x];
        this.setState({ teamBList: itemB, teamA: true });
      }
    }
  };

  removeFromActiveList = (y) => {
    const itemA = this.state.teamAList.filter((item) => item !== y);
    const itemB = this.state.teamBList.filter((item) => item !== y);
    this.setState({
      teamAList: itemA,
      teamBList: itemB,
    });
  };
  studentTotal = (x) => {
    const ansList = this.state.answerList.filter(
      (item) => item.user_name === x
    );
    const marks = ansList.map((item) =>
      item.user_answer === ""
        ? 0
        : item.user_answer === item.correct_answer
        ? 10
        : -10
    );
    const total =
      marks.length > 0
        ? marks.reduce((a, c) => {
            return a + c;
          })
        : 0;
    return total;
  };
  teamAtotal = () => {
    const list = this.state.teamAList;
    const ansList = this.state.answerList.filter((item) =>
      list.includes(item.user_name)
    );
    const marks = ansList.map((item) =>
      item.user_answer === ""
        ? 0
        : item.user_answer === item.correct_answer
        ? 10
        : -10
    );
    const total =
      marks.length > 0
        ? marks.reduce((a, c) => {
            return a + c;
          })
        : 0;
    return total;
  };
  teamBtotal = () => {
    const list = this.state.teamBList;
    const ansList = this.state.answerList.filter((item) =>
      list.includes(item.user_name)
    );
    const marks = ansList.map((item) =>
      item.user_answer === ""
        ? 0
        : item.user_answer === item.correct_answer
        ? 10
        : -10
    );
    const total =
      marks.length > 0
        ? marks.reduce((a, c) => {
            return a + c;
          })
        : 0;
    return total;
  };
  renderListA = () => {
    const listA = this.state.teamAList;

    return listA.map((item) => (
      <td
        style={{
          border: "none",
          order: this.studentTotal(item),
          marginTop: 10,
        }}
      >
        <button
          className="lista"
          onClick={() => this.removeAllAnswers(item)}
          style={{
            width: "35%",
            border: "none",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            minHeight: "5vh",
            backgroundColor: "rgb(15,72,50)",
            fontSize: 25,
            color:
              this.studentTotal(item) === 0
                ? "white"
                : this.studentTotal(item) > 0
                ? "lightgreen"
                : "red",
          }}
        >
          {this.studentTotal(item)}
        </button>
        <button
          className="lista"
          style={{
            width: "35%",
            border: "none",
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            minHeight: "5vh",
            backgroundColor: "rgb(15,72,50)",
            fontSize: 25,
            color:
              this.studentTotal(item) === 0
                ? "white"
                : this.studentTotal(item) > 0
                ? "lightgreen"
                : "red",
          }}
          onClick={() => this.removeFromActiveList(item)}
        >
          {item}
        </button>
      </td>
    ));
  };
  renderListB = (x) => {
    const listB = this.state.teamBList;
    return listB.map((item, i) => (
      <td
        style={{
          border: "none",
          order: this.studentTotal(item),
          marginTop: 10,
        }}
      >
        <button
          className="listb"
          style={{
            width: "35%",
            border: "none",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            minHeight: "5vh",
            backgroundColor: "rgb(15,72,50)",
            fontSize: 25,
            color:
              this.studentTotal(item) === 0
                ? "white"
                : this.studentTotal(item) > 0
                ? "lightgreen"
                : "red",
          }}
          onClick={() => this.removeFromActiveList(item)}
        >
          {item}
        </button>
        <button
          className="listb"
          onClick={() => this.removeAllAnswers(item)}
          style={{
            width: "35%",
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            border: "none",
            minHeight: "5vh",
            backgroundColor: "rgb(15,72,50)",
            fontSize: 25,
            color:
              this.studentTotal(item) === 0
                ? "white"
                : this.studentTotal(item) > 0
                ? "lightgreen"
                : "red",
          }}
        >
          {this.studentTotal(item)}
        </button>
      </td>
    ));
  };
  render() {
    return (
      <div
        style={{
          backgroundColor: "rgb(22,107,75)",
          width: "100%",
          height: "200vh",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", minHeight: "80vh" }}
        >
          <table class="table table-hover" style={{ border: "none" }}>
            <thead>
              <tr>
                <th scope="col" style={{ border: "none" }}>
                  <button
                    style={{
                      width: "40%",
                      border: "none",
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      minHeight: "10vh",
                      backgroundColor: "rgb(15,72,50)",
                      fontSize: 30,
                      color:
                        this.teamAtotal() === this.teamBtotal()
                          ? "white"
                          : this.teamAtotal() > this.teamBtotal()
                          ? "lightgreen"
                          : "red",
                    }}
                  >
                    {this.teamAtotal()}
                  </button>
                  <button
                    style={{
                      width: "40%",
                      border: "none",
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      minHeight: "10vh",
                      backgroundColor: "rgb(15,72,50)",
                      fontSize: 30,
                      color:
                        this.teamAtotal() === this.teamBtotal()
                          ? "white"
                          : this.teamAtotal() > this.teamBtotal()
                          ? "lightgreen"
                          : "red",
                    }}
                  >
                    TEAM A
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr style={{ display: "flex", flexDirection: "column-reverse" }}>
                {this.renderListA()}
              </tr>
            </tbody>
          </table>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" style={{ border: "none" }}>
                  <button
                    style={{
                      width: "40%",
                      border: "none",
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      minHeight: "10vh",
                      backgroundColor: "rgb(15,72,50)",
                      fontSize: 30,
                      color:
                        this.teamAtotal() === this.teamBtotal()
                          ? "white"
                          : this.teamBtotal() > this.teamAtotal()
                          ? "lightgreen"
                          : "red",
                    }}
                  >
                    TEAM B
                  </button>
                  <button
                    style={{
                      width: "40%",
                      border: "none",
                      borderTopRightRadius: 10,
                      borderBottomRightRadius: 10,
                      minHeight: "10vh",
                      backgroundColor: "rgb(15,72,50)",
                      fontSize: 30,
                      color:
                        this.teamAtotal() === this.teamBtotal()
                          ? "white"
                          : this.teamBtotal() > this.teamAtotal()
                          ? "lightgreen"
                          : "red",
                    }}
                  >
                    {this.teamBtotal()}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ display: "flex", flexDirection: "column-reverse" }}>
                {this.renderListB()}
              </tr>
            </tbody>
          </table>
        </div>
        <button
          style={{ backgroundColor: "rgb(22,107,75)" }}
          onClick={() => this.setState({ showOn: !this.state.showOn })}
        >
          list
        </button>
        {this.state.sheet_name !== "" ? (
          <>
            <button
              style={{ backgroundColor: "rgb(22,107,75)" }}
              onClick={() => this.activeUserList()}
            >
              add
            </button>
            <button
              style={{ backgroundColor: "rgb(22,107,75)" }}
              onClick={() => this.startTest()}
            >
              start
            </button>
          </>
        ) : null}
        <div className={this.state.showOn ? null : "showAllStudent"}>
          {this.studentList()}
          {this.renderFilter()}
          {this.state.isCheating & (this.state.cheater !== "1")
            ? this.textToSpeech(`${this.state.cheater} is cheating`)
            : null}
        </div>
      </div>
    );
  }
}
