import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import ALLStudents from "./pages/AllStudents";
import monitor from "./pages/monitor";
import MCCUBEStudents from "./pages/MCCUBEStudents";
import livePerformance from "./pages/livePerformance";
import LiveTestPerformance from "./pages/LiveTestPerformance";
import Logout from "./components/Logout";
import CustomNavbar from "./components/Navbar";
import TestAnalysis from "./pages/TestAnalysis";
import StudentSheets from "./pages/StudentSheets";
import StudentSyllabus from "./pages/StudentSyllabus";
import Quiz from "./pages/Quiz";
import "./App.css";

function App() {
  return (
    <HashRouter>
      <CustomNavbar />
      <div className="App">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/allstudents" component={ALLStudents} />
          <Route path="/monitor" component={monitor} />
          <Route path="/mccubestudents" component={MCCUBEStudents} />
          <Route path="/liveperformance" component={livePerformance} />
          <Route path="/livetestperformance" component={LiveTestPerformance} />
          <Route path="/studentsheets" component={StudentSheets} />
          <Route path="/studentsyllabus" component={StudentSyllabus} />
          <Route path="/testanalysis" component={TestAnalysis} />
          <Route path="/quiz" component={Quiz} />
          <Route path="/logout" component={Logout} />
        </Switch>
      </div>
    </HashRouter>
  );
}

export default App;
