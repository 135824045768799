import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import "../components/Login.css";
import { CSVLink } from "react-csv";
import Calculator from "../components/Calculator";
import { Collapse } from "reactstrap";
import ReactPlayer from "react-player";
import {
  changeClearOn,
  changeClearInput,
  changeSelectOn,
  changeSelectInput,
  changeJoinOn,
  changeJoinInput,
  changeRemoveOn,
  changeRemoveInput,
  changeSheetOn,
  changeSheetInput,
  changeQuestionOn,
  changeQuestionInput,
  changeHintOn,
  changeVideoOn,
  changeFullScreenOn,
  changePlayOn,
  changeShowOn,
} from "../actions/action";
import SpeechToText from "./speechToText";

class LiveTestPerformance extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      viewCompleted: false,
      loggedIn,
      filterOn: true,
      testPaperList: [],
      answerList: [],
      studentList: [],
      flagList: [],
      activeStudentList: [],
      test_name: "",
      showAllStudent: true,
      currentStudent: "",
      questionList: [],
      isSolutionOpen: false,
      isSolutionVideoOpen: false,
      currentQuestion: "",
      isCalcOpen: false,
      currentTest: "",
      liveUpdate: false,
    };
  }
  componentDidMount() {
    this.List();
    this.testList();
    this.answersList();
    this.flagList();
    this.userList();
    setInterval(() => {
      this.liveUpdate();
    }, 1000);
  }
  liveUpdate = () => {
    if (this.state.liveUpdate === true) {
      this.answersList();
      this.flagList();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      filterOn: true,
      currentQuestion: "",
      currentTest: this.state.testPaperList.filter(
        (item) => item.test_name === e.target.value
      )[0],
    });
  };
  hideStudents = () => {
    this.setState({ showAllStudent: !this.state.showAllStudent });
  };
  removeFromActiveList = (y) => {
    const item = this.state.activeStudentList.filter((item) => item !== y);
    this.setState({ activeStudentList: item });
  };

  refreshState = () => {
    this.answersList();
    this.flagList();
    this.List();
  };

  List = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/testpaper/questions/?test_name=${this.state.test_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) =>
        this.setState({ questionList: res.data, filterOn: false })
      );
  };
  testList = () => {
    axios
      .get(`https://backend.mccubeindia.com/testpaper/testpaper/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          testPaperList: res.data,
          filterOn: false,
        })
      )
      .catch((err) => console.log(err));
  };
  userList = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/level/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          studentList: res.data,
          filterOn: false,
        })
      )
      .catch((err) => console.log(err));
  };

  updateQuestion = (e) => {
    this.setState({
      currentQuestion: {
        ...this.state.currentQuestion,
        [e.target.name]: e.target.value,
      },
    });
  };

  saveQuestion = () => {
    const id = this.state.currentQuestion.id;
    const item = this.state.currentQuestion;
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/testpaper/questions/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.List());
  };

  updateTest = (e) => {
    this.setState({
      currentTest: {
        ...this.state.currentTest,
        [e.target.name]: e.target.value,
      },
    });
  };
  saveTest = () => {
    const id = this.state.currentTest.id;
    const item = this.state.currentTest;
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/testpaper/testpaper/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then(() => this.testList())
      .catch((err) => console.log(err));
  };

  answersList = () => {
    axios
      .get(`https://backend.mccubeindia.com/testpaper/useranswers/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          answerList: res.data,
          filterOn: false,
        })
      )
      .catch((err) => console.log(err));
  };

  flagList = () => {
    axios
      .get(`https://backend.mccubeindia.com/testpaper/flag/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          flagList: res.data,
          filterOn: false,
        })
      )
      .catch((err) => console.log(err));
  };
  renderTestPapers = () => {
    const newItems = this.state.testPaperList;
    return newItems.map((item) => <option>{item.test_name}</option>);
  };

  renderFilter = () => {
    return (
      <div className="listingform__section pfiltersection">
        <label className="listingform__label pfilterlabel" htmlFor="topic">
          Select Test
        </label>
        <select
          className="listingform__select pfilterselect"
          name="test_name"
          onChange={this.handleChange}
          value={this.state.test_name}
        >
          <option></option>
          {this.renderTestPapers()}
        </select>
      </div>
    );
  };

  activateStudent = (x) => {
    const item = [...this.state.activeStudentList, x];
    this.setState({
      activeStudentList: item,
    });
  };

  studentList = () => {
    const activeStudentList = this.state.activeStudentList;
    const newItem = this.state.studentList;
    return newItem.map((item) => (
      <button
        className={
          activeStudentList.includes(item.user_name)
            ? "pselectedstudents"
            : "pallstudents"
        }
        onClick={() => this.activateStudent(item.user_name)}
      >
        {item.user_name}
      </button>
    ));
  };

  renderQuestion = () => {
    return (
      <div className="topicsheet">
        <div class="input-group input-group-lg">
          <button
            className="remove"
            onClick={() => this.setState({ currentQuestion: "" })}
          >
            X
          </button>
          Question. {this.state.currentQuestion.question_no})
          <div className="currentQuestion.question">
            <textarea
              onChange={this.updateQuestion}
              name="question"
              style={{ border: "none" }}
              rows={~~(this.state.currentQuestion.question.length / 150) + 1}
              cols="150"
              value={this.state.currentQuestion.question
                .split("<br>")
                .map((i) => i)}
            />
            {this.state.currentQuestion.question_image === "1" ? null : this
                .state.currentQuestion.question_image === "" ? null : (
              <>
                <img
                  src={
                    this.state.url + this.state.currentQuestion.question_image
                  }
                  alt=""
                  className="qimage"
                />
                <img
                  src={
                    this.state.url2 + this.state.currentQuestion.question_image
                  }
                  alt=""
                  className="qimage"
                />
              </>
            )}
          </div>
        </div>
        <div>
          <div class="list-group">
            <div>
              (A)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_a.length / 25) + 1}
                cols="25"
                name="option_a"
                value={this.state.currentQuestion.option_a}
                className={
                  this.state.currentQuestion.correct_answer === "a"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />
              (B)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_b.length / 25) + 1}
                cols="25"
                name="option_b"
                value={this.state.currentQuestion.option_b}
                className={
                  this.state.currentQuestion.correct_answer === "b"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />
              (C)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_c.length / 25) + 1}
                cols="25"
                name="option_c"
                value={this.state.currentQuestion.option_c}
                className={
                  this.state.currentQuestion.correct_answer === "c"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />
              (D)
              <textarea
                onChange={this.updateQuestion}
                rows={~~(this.state.currentQuestion.option_d.length / 25) + 1}
                cols="25"
                name="option_d"
                value={this.state.currentQuestion.option_d}
                className={
                  this.state.currentQuestion.correct_answer === "d"
                    ? "correctoption"
                    : "incorrectoption"
                }
              />{" "}
              Ans.{" "}
              <input
                onChange={this.updateQuestion}
                name="correct_answer"
                style={{ border: "none", width: 30 }}
                value={this.state.currentQuestion.correct_answer}
              />
              <button className="button2" onClick={() => this.saveQuestion()}>
                save changes
              </button>
            </div>

            <div className="bottombuttons">
              <div className="hint">
                {this.props.hintOn ? this.dmsHint() : null}
                <button
                  className="button2"
                  onClick={() =>
                    this.setState({
                      isSolutionOpen: !this.state.isSolutionOpen,
                    })
                  }
                >
                  Solution
                </button>
                <Collapse isOpen={this.state.isSolutionOpen}>
                  <textarea
                    onChange={this.updateQuestion}
                    name="hint"
                    style={{ border: "none" }}
                    rows="10"
                    cols="150"
                    value={this.state.currentQuestion.solution
                      .split("<br>")
                      .map((i) => i)}
                  />
                  {this.state.currentQuestion.solution_image ===
                  "1" ? null : this.state.currentQuestion.solution_image ===
                    "" ? null : (
                    <img
                      src={
                        this.state.url +
                        this.state.currentQuestion.solution_image
                      }
                      alt=""
                      className="qimage"
                    />
                  )}
                </Collapse>
              </div>
              <div className="hint_video">
                {this.props.videoOn ? this.dmsVideo() : null}
                <button
                  className="button2"
                  onClick={() =>
                    this.setState({
                      isSolutionVideoOpen: !this.state.isSolutionVideoOpen,
                    })
                  }
                >
                  solution video
                </button>
              </div>
            </div>
          </div>

          <Collapse isOpen={this.state.isSolutionVideoOpen}>
            <div className={this.props.fullScreenOn ? "fullscreen" : "wrapper"}>
              <ReactPlayer
                playing={this.props.playOn}
                ref="player"
                controls
                url={this.state.url + this.state.currentQuestion.solution_video}
                width="100%"
                height="100%"
                className="player"
              />
            </div>
          </Collapse>
        </div>
        <button
          className="button2"
          onClick={() => this.setState({ isCalcOpen: !this.state.isCalcOpen })}
        >
          Calculator
        </button>
        question_image
        <input
          onChange={this.updateQuestion}
          name="question_image"
          value={this.state.currentQuestion.question_image}
        />
        solution_image
        <input
          onChange={this.updateQuestion}
          name="hint_image"
          value={this.state.currentQuestion.solution_image}
        />
        solution_video
        <input
          onChange={this.updateQuestion}
          name="hint_video"
          value={this.state.currentQuestion.solution_video}
        />
        {this.state.isCalcOpen ? <Calculator /> : null}
        <CSVLink
          data={this.state.questionList}
          filename={`E_${this.state.sheet_name}`}
        >
          Export
        </CSVLink>
      </div>
    );
  };

  dmsHint = () => {
    this.setState({ isSolutionOpen: !this.state.isSolutionOpen });
    this.props.changeHintOn(false);
  };
  dmsVideo = () => {
    this.setState({ isSolutionVideoOpen: !this.state.isSolutionVideoOpen });
    this.props.changeVideoOn(false);
  };

  selectQuestion = (x) => {
    if (
      (this.state.questionList.length !== 0) &
      (x <= this.state.questionList.length)
    ) {
      this.setState({
        isHintOpen: false,
        isHintVideoOpen: false,
        currentQuestion: this.state.questionList[x - 1],
      });
      this.props.changeQuestionOn(false);
      this.props.changeQuestionInput("");
    } else {
      alert("wait");
    }
  };

  renderAnswers = (y) => {
    const no_of_questions = this.state.testPaperList
      .filter((item) => item.test_name === this.state.test_name)
      .map((item) => item.no_of_questions);
    const item = this.state.answerList.filter(
      (item) => item.test_name === this.state.test_name
    );
    const newItem = item.filter((item) => item.user_name === y);
    const correct = newItem
      .filter((item) => item.user_answer === item.correct_answer)
      .map((item) => item.question_no);
    const incorrect = newItem
      .filter((item) => item.user_answer !== item.correct_answer)
      .map((item) => item.question_no);
    const questions = Array(no_of_questions[0])
      .fill()
      .map((x, i) => i + 1);
    const flagItem = this.state.flagList.filter(
      (item) => item.test_name === this.state.test_name
    );
    const flagNewItem = flagItem.filter((item) => item.user_name === y);
    const flag = flagNewItem.map((item) => item.question_no);
    return (
      <div style={{ order: correct.length }}>
        <button
          className="pincorrecttotal"
          onClick={() => this.removeFromActiveList(y)}
        >
          X
        </button>
        <button
          className="pactivestudents"
          onClick={() => this.setState({ currentStudent: y })}
        >
          {y}
        </button>
        {questions.map((item) => (
          <button
            className={
              flag.includes(item)
                ? correct.includes(item)
                  ? "pcorrect flag"
                  : incorrect.includes(item)
                  ? "pincorrect flag"
                  : "pfnormal flag"
                : correct.includes(item)
                ? "pcorrect"
                : incorrect.includes(item)
                ? "pincorrect"
                : "pnormal"
            }
            onClick={() => this.selectQuestion(item)}
          >
            {item}
          </button>
        ))}
        <button className="pcorrecttotal">{correct.length}</button>
        <button className="pincorrecttotal">{incorrect.length}</button>
        <button className="pnormaltotal">
          {no_of_questions - correct.length - incorrect.length}
        </button>
      </div>
    );
  };
  delAns = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/testpaper/useranswers/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => console.log(err));
  };
  clrDbt = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/testpaper/flag/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => console.log(err));
  };

  removeAllAnswers = (x) => {
    x.forEach((item) => {
      this.delAns(item.id);
    });
  };

  removeAllDoubts = (x) => {
    x.forEach((item) => {
      this.clrDbt(item.id);
    });
  };

  renderSeprateAnswers = () => {
    const item = this.state.answerList.filter(
      (item) => item.test_name === this.state.test_name
    );
    const newItem = item.filter(
      (item) => item.user_name === this.state.currentStudent
    );
    const incorrect = newItem.filter(
      (item) => item.user_answer !== item.correct_answer
    );
    return (
      <div>
        {this.state.currentStudent !== "" ? (
          <button className="pselectedstudents">
            {this.state.currentStudent} Answers
          </button>
        ) : null}
        {newItem.map((item) => (
          <button className="pallstudents" onClick={() => this.delAns(item.id)}>
            {item.question_no}
          </button>
        ))}
        {this.state.currentStudent !== "" ? (
          <>
            <button
              onClick={() => this.removeAllAnswers(newItem)}
              className="pselectedstudents"
            >
              remove all
            </button>
            <button
              onClick={() => this.removeAllAnswers(incorrect)}
              className="pselectedstudents"
            >
              remove all incorrect
            </button>
          </>
        ) : null}
        {(this.state.currentStudent !== "") & (this.props.clearInput === "all")
          ? this.removeAllAnswers(newItem)
          : null}
        {(this.state.currentStudent !== "") &
        (this.props.clearInput === "incorrect")
          ? this.removeAllAnswers(incorrect)
          : null}
      </div>
    );
  };

  renderSeprateDoubts = () => {
    const item = this.state.flagList.filter(
      (item) => item.test_name === this.state.test_name
    );
    const newItem = item.filter(
      (item) => item.user_name === this.state.currentStudent
    );
    return (
      <div>
        {this.state.currentStudent !== "" ? (
          <button className="pselectedstudents">
            {this.state.currentStudent} Doubts
          </button>
        ) : null}
        {newItem.map((item) => (
          <button className="pallstudents" onClick={() => this.clrDbt(item.id)}>
            {item.question_no}
          </button>
        ))}
        {this.state.currentStudent !== "" ? (
          <button
            onClick={() => this.removeAllDoubts(newItem)}
            className="pselectedstudents"
          >
            remove all
          </button>
        ) : null}
        {(this.state.currentStudent !== "") &
        (this.props.clearInput === "doubt")
          ? this.removeAllDoubts(newItem)
          : null}
      </div>
    );
  };
  renderActiveStudents = () => {
    const activeStudentList = this.state.activeStudentList;
    return activeStudentList.map((item) => this.renderAnswers(item));
  };

  render() {
    const liveUpdate = this.state.liveUpdate ? "Off" : "On";
    if (this.props.newuser !== "yogesh") {
      this.props.history.push("logout");
    }
    return (
      <div>
        {this.state.filterOn ? this.List() : null}
        <button className="pactivestudents" onClick={() => this.hideStudents()}>
          {this.state.showAllStudent ? "hide all" : "show all"}
        </button>
        <button
          onClick={() => this.setState({ liveUpdate: !this.state.liveUpdate })}
          className="livetestpaper"
        >
          Turn {liveUpdate} Live Update
        </button>
        <button onClick={() => this.refreshState()} className="livetestpaper">
          Refresh
        </button>
        <SpeechToText />
        <div className={this.state.showAllStudent ? null : "showAllStudent"}>
          {this.studentList()}
        </div>
        <br />
        <div>{this.renderFilter()}</div>
        <br />
        <div className="renderActiveStudents">
          {this.renderActiveStudents()}
        </div>
        <div>{this.renderSeprateAnswers()}</div>
        <div>{this.renderSeprateDoubts()}</div>
        {this.props.questionOn &
        (this.props.questionInput !== "") &
        (this.state.questionList.length !== 0)
          ? this.selectQuestion(this.props.questionInput)
          : null}
        {this.state.currentQuestion.length !== 0 ? this.renderQuestion() : null}
        {this.state.currentTest !== "" ? (
          <div>
            <label>Number of questions</label>
            <input
              onChange={this.updateTest}
              name="no_of_questions"
              value={this.state.currentTest.no_of_questions}
            />
            <button onClick={() => this.saveTest()}>save changes</button>
          </div>
        ) : null}
        <CSVLink
          data={this.state.questionList}
          filename={`E_TEST_ALL_QUESTIONS`}
        >
          Export all questions
        </CSVLink>
        <br></br>
        <CSVLink data={this.state.testPaperList} filename={`E_TEST_LIST`}>
          Export all test name
        </CSVLink>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
    clearInput: state.clearInput,
    selectInput: state.selectInput,
    joinInput: state.joinInput,
    removeInput: state.removeInput,
    sheetInput: state.sheetInput,
    sheetOn: state.sheetOn,
    questionInput: state.questionInput,
    questionOn: state.questionOn,
    hintOn: state.hintOn,
    videoOn: state.videoOn,
    fullScreenOn: state.fullScreenOn,
    playOn: state.playOn,
    showOn: state.showOn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeClearOn: (clearOn) => {
      dispatch(changeClearOn(clearOn));
    },
    changeClearInput: (clearInput) => {
      dispatch(changeClearInput(clearInput));
    },
    changeSelectOn: (selectOn) => {
      dispatch(changeSelectOn(selectOn));
    },
    changeSelectInput: (selectInput) => {
      dispatch(changeSelectInput(selectInput));
    },
    changeJoinOn: (joinOn) => {
      dispatch(changeJoinOn(joinOn));
    },
    changeJoinInput: (joinInput) => {
      dispatch(changeJoinInput(joinInput));
    },
    changeRemoveOn: (removeOn) => {
      dispatch(changeRemoveOn(removeOn));
    },
    changeRemoveInput: (removeInput) => {
      dispatch(changeRemoveInput(removeInput));
    },
    changeSheetOn: (sheetOn) => {
      dispatch(changeSheetOn(sheetOn));
    },
    changeSheetInput: (sheetInput) => {
      dispatch(changeSheetInput(sheetInput));
    },
    changeQuestionOn: (questionOn) => {
      dispatch(changeQuestionOn(questionOn));
    },
    changeQuestionInput: (questionInput) => {
      dispatch(changeQuestionInput(questionInput));
    },
    changeHintOn: (hintOn) => {
      dispatch(changeHintOn(hintOn));
    },
    changeVideoOn: (videoOn) => {
      dispatch(changeVideoOn(videoOn));
    },
    changeFullScreenOn: (fullScreenOn) => {
      dispatch(changeFullScreenOn(fullScreenOn));
    },
    changePlayOn: (playOn) => {
      dispatch(changePlayOn(playOn));
    },
    changeShowOn: (showOn) => {
      dispatch(changeShowOn(showOn));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveTestPerformance);
