import React, { Component } from "react";
import Modal from "./MCCUBEModal";
import axios from "axios";
import { connect } from "react-redux";

class MCCUBEStudents extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      viewCompleted: false,
      loggedIn,
      filterOn: true,
      name: "",
      number: "",
      college: "",
      year: "",
      semester: "",
      section: "",
      activeItem: {
        photo: "",
        name: "",
        number: "",
        fathername: "",
        mothername: "",
        address: "",
        college: "",
        stream: "",
        year: "",
        semester: "",
        section: "",
        reference: "",
        tenthpercentage: "",
        twelthpercentage: "",
        graduationpercentage: "",
        course: "",
        datejoined: "",
        monthjoined: "",
        totalfees: "",
        feespaid: "",
        feesremaining: "",
        nextfeesdate: "",
        nextfeesmonth: "",
        feescompleted: false,
      },
      todoList: [],
    };
  }
  componentDidMount() {
    this.refreshList();
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    this.setState({ [name]: value, filterOn: true });
  };
  refreshList = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/studentdetail/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ todoList: res.data, filterOn: false }))
      .catch((err) => console.log(err));
  };
  renderFilter = () => {
    return (
      <div>
        <div className="row">
          <div className="filterdev">
            <input
              className="filterinput"
              name="name"
              onChange={this.handleChange}
              value={this.state.name}
              placeholder="search by name"
            />
          </div>
          <div className="filterdev">
            <input
              className="filterinput"
              name="year"
              onChange={this.handleChange}
              value={this.state.year}
              placeholder="search by year"
            />
          </div>
        </div>
        <div className="row">
          <div className="filterdev">
            <input
              className="filterinput"
              name="courser"
              onChange={this.handleChange}
              value={this.state.course}
              placeholder="search by semester"
            />
          </div>
          <div className="filterdev">
            <label>Fees Completed</label>
            <input
              type="chkbox"
              name="feescompleted"
              onChange={this.handleChange}
              value={this.state.feescompleted}
            />
          </div>
        </div>
      </div>
    );
  };
  renderItems = () => {
    const newItems = this.state.todoList;
    return newItems.map((item) => (
      <tr>
        <th scope="row">
          <p className="namelist">{item.name}</p>
        </th>
        <td>
          <p className="namelist">{item.number}</p>
        </td>
        <td>
          <button
            onClick={() => this.editItem(item)}
            className="btn btn-secondary mr-2"
          >
            {" "}
            View{" "}
          </button>
          <button
            onClick={() => this.handleDelete(item)}
            className="btn btn-danger"
          >
            Delete{" "}
          </button>
        </td>
      </tr>
    ));
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  handleSubmit = (item) => {
    this.toggle();
    if (item.id) {
      axios
        .put(
          `https://backend.mccubeindia.com/user/studentdetail/${item.id}/`,
          item,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => this.refreshList());
      return;
    } else {
      axios
        .post(`https://backend.mccubeindia.com/user/studentdetail/`, item, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => this.refreshList());
    }
  };
  handleDelete = (item) => {
    axios
      .delete(
        `https://backend.mccubeindia.com/user/studentdetail/${item.id}/`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.refreshList());
  };
  createItem = () => {
    const item = {
      photo: "",
      name: "",
      number: "",
      fathername: "",
      mothername: "",
      address: "",
      college: "",
      stream: "",
      year: "",
      semester: "",
      section: "",
      reference: "",
      tenthpercentage: "",
      twelthpercentage: "",
      graduationpercentage: "",
      course: "",
      datejoined: "",
      monthjoined: "",
      totalfees: "",
      feespaid: "",
      feesremaining: "",
      nextfeesdate: "",
      nextfeesmonth: "",
      feescompleted: false,
    };
    this.setState({ activeItem: item, modal: !this.state.modal });
  };
  editItem = (item) => {
    this.setState({ activeItem: item, modal: !this.state.modal });
  };
  render() {
    if (this.props.newuser !== "yogesh") {
      this.props.history.push("logout");
    }
    return (
      <div>
        {this.state.filterOn ? this.refreshList() : null}
        <main className="content">
          <div className="row ">
            <div className="col-md-6 col-sm-10 mx-auto p-0">
              <div className="card p-3">
                {this.renderFilter()}
                <div className="">
                  <button onClick={this.createItem} className="btn btn-primary">
                    Add New
                  </button>
                  <button
                    onClick={() => this.props.history.push("studentsheets")}
                    className="btn btn-primary"
                  >
                    Student Sheets
                  </button>
                  <button
                    onClick={() => this.props.history.push("studentsyllabus")}
                    className="btn btn-primary"
                  >
                    Student Syllabus
                  </button>
                  <button
                    onClick={() => this.props.history.push("testanalysis")}
                    className="btn btn-primary"
                  >
                    Test Analyse
                  </button>
                </div>
                <ul className="list-group list-group-flush">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">
                          <p className="namelist">Name</p>
                        </th>
                        <th scope="col">
                          <p className="numberlist">Number</p>
                        </th>
                        <th scope="col">
                          <p className="action">Action</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderItems()}</tbody>
                  </table>
                </ul>
              </div>
            </div>
          </div>
          {this.state.modal ? (
            <Modal
              activeItem={this.state.activeItem}
              toggle={this.toggle}
              onSave={this.handleSubmit}
            />
          ) : null}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
  };
};

export default connect(mapStateToProps)(MCCUBEStudents);
