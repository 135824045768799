//1
export const changeSheet = (sheet) => {
  return {
    type: "CHANGE_SHEET",
    payload: sheet,
  };
};
//2
export const changeTestPaper = (testpaper) => {
  return {
    type: "CHANGE_TEST_PAPER",
    payload: testpaper,
  };
};
//3
export const changeCurrentUser = (currentUser) => {
  return {
    type: "CHANGE_USERNAME",
    payload: currentUser,
  };
};
//4
export const changeCurrentUserLevel = (currentUserLevel) => {
  return {
    type: "CHANGE_USER_LEVEL",
    payload: currentUserLevel,
  };
};
//5
export const changeStudent = (student) => {
  return {
    type: "CHANGE_STUDENT",
    payload: student,
  };
};
//6
export const changeClearOn = (clearOn) => {
  return {
    type: "CHANGE_CLEAR_ON",
    payload: clearOn,
  };
};
//7
export const changeClearInput = (clearInput) => {
  return {
    type: "CHANGE_CLEAR_INPUT",
    payload: clearInput,
  };
};
//8
export const changeSelectOn = (selectOn) => {
  return {
    type: "CHANGE_SELECT_ON",
    payload: selectOn,
  };
};
//9
export const changeSelectInput = (selectInput) => {
  return {
    type: "CHANGE_SELECT_INPUT",
    payload: selectInput,
  };
};
//10
export const changeJoinOn = (joinOn) => {
  return {
    type: "CHANGE_JOIN_ON",
    payload: joinOn,
  };
};
//11
export const changeJoinInput = (joinInput) => {
  return {
    type: "CHANGE_JOIN_INPUT",
    payload: joinInput,
  };
};
//12
export const changeRemoveOn = (removeOn) => {
  return {
    type: "CHANGE_REMOVE_ON",
    payload: removeOn,
  };
};
//13
export const changeRemoveInput = (removeInput) => {
  return {
    type: "CHANGE_REMOVE_INPUT",
    payload: removeInput,
  };
};
//14
export const changeSheetOn = (sheetOn) => {
  return {
    type: "CHANGE_SHEET_ON",
    payload: sheetOn,
  };
};
//15
export const changeSheetInput = (sheetInput) => {
  return {
    type: "CHANGE_SHEET_INPUT",
    payload: sheetInput,
  };
};
//16
export const changeQuestionInput = (questionInput) => {
  return {
    type: "CHANGE_QUESTION_INPUT",
    payload: questionInput,
  };
};
//17
export const changeQuestionOn = (questionOn) => {
  return {
    type: "CHANGE_QUESTION_ON",
    payload: questionOn,
  };
};
//18
export const changeHintOn = (hintOn) => {
  return {
    type: "CHANGE_HINT_ON",
    payload: hintOn,
  };
};
//19
export const changeVideoOn = (videoOn) => {
  return {
    type: "CHANGE_VIDEO_ON",
    payload: videoOn,
  };
};
//20
export const changeFullScreenOn = (fullScreenOn) => {
  return {
    type: "CHANGE_FULL_SCREEN_ON",
    payload: fullScreenOn,
  };
};
//21
export const changePlayOn = (playOn) => {
  return {
    type: "CHANGE_PLAY_ON",
    payload: playOn,
  };
};
//22
export const changeCalculatorOn = (calculatorOn) => {
  return {
    type: "CHANGE_CALCULATOR_ON",
    payload: calculatorOn,
  };
};
//23
export const changeCalculatorInput = (calculatorInput) => {
  return {
    type: "CHANGE_CALCULATOR_INPUT",
    payload: calculatorInput,
  };
};
//24
export const changeAnswerOn = (answerOn) => {
  return {
    type: "CHANGE_ANSWER_ON",
    payload: answerOn,
  };
};
//25
export const changeVocabOn = (vocabOn) => {
  return {
    type: "CHANGE_VOCAB_ON",
    payload: vocabOn,
  };
};

//26
export const changeVocabInput = (vocabInput) => {
  return {
    type: "CHANGE_VOCAB_INPUT",
    payload: vocabInput,
  };
};
//27
export const changeVocabAnswerOn = (vocabAnswerOn) => {
  return {
    type: "CHANGE_VOCAB_ANSWER_ON",
    payload: vocabAnswerOn,
  };
};
//28
export const changeShowOn = (showOn) => {
  return {
    type: "CHANGE_SHOW_ON",
    payload: showOn,
  };
};
