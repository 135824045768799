import React, { Component } from "react";
import Speech from "speak-tts";
import { changeVocabInput, changeVocabAnswerOn } from "../actions/action";
import { connect } from "react-redux";
import axios from "axios";

class Vocab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wordDetails: [],
      word: "",
      meaning: "",
    };
  }

  wordToMeaning = () => {
    const word = this.props.vocabInput.split(" ")[0];
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
      .then((res) => this.setState({ wordDetails: res.data }))
      .then(() =>
        this.setState({
          word: word,
          meaning:
            this.state.wordDetails[0].meanings[0].definitions[0].definition,
        })
      );
    this.props.changeVocabAnswerOn(false);
    this.props.changeVocabInput("");
    setTimeout(() => {
      this.textToSpeech(`means "${this.state.meaning}`);
    }, 2000);
  };

  textToSpeech = (x) => {
    const speech = new Speech();
    speech.speak({
      text: x,
    });
  };

  render() {
    return (
      <div>
        {this.props.vocabAnswerOn ? this.wordToMeaning() : null}
        <input
          className="speechinput"
          value={`${this.state.word} = ${this.state.meaning}`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vocabInput: state.vocabInput,
    vocabAnswerOn: state.vocabAnswerOn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeVocabInput: (vocabInput) => {
      dispatch(changeVocabInput(vocabInput));
    },
    changeVocabAnswerOn: (vocabAnswerOn) => {
      dispatch(changeVocabAnswerOn(vocabAnswerOn));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vocab);
