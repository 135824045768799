const initialState = {
  sheet: "",
  testpaper: "",
  currentUser: "",
  currentUserLevel: "",
  student: "",
  clearOn: false,
  clearInput: "",
  selectOn: false,
  selectInput: "",
  joinOn: false,
  joinInput: "",
  removeOn: false,
  removeInput: "",
  sheetOn: false,
  sheetInput: "",
  questionInput: "",
  questionOn: false,
  hintOn: false,
  videoOn: false,
  fullScreenOn: false,
  playOn: false,
  calculatorOn: false,
  calculatorInput: "",
  answerOn: false,
  vocabOn: false,
  vocabInput: "",
  vocabAnswerOn: false,
  showOn: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SHEET":
      return { ...state, sheet: action.payload };
    case "CHANGE_TEST_PAPER":
      return { ...state, testpaper: action.payload };
    case "CHANGE_USERNAME":
      return { ...state, currentUser: action.payload };
    case "CHANGE_USER_LEVEL":
      return { ...state, currentUserLevel: action.payload };
    case "CHANGE_STUDENT":
      return { ...state, student: action.payload };
    case "CHANGE_CLEAR_ON":
      return { ...state, clearOn: action.payload };
    case "CHANGE_CLEAR_INPUT":
      return { ...state, clearInput: action.payload };
    case "CHANGE_SELECT_ON":
      return { ...state, selectOn: action.payload };
    case "CHANGE_SELECT_INPUT":
      return { ...state, selectInput: action.payload };
    case "CHANGE_JOIN_ON":
      return { ...state, joinOn: action.payload };
    case "CHANGE_JOIN_INPUT":
      return { ...state, joinInput: action.payload };
    case "CHANGE_REMOVE_ON":
      return { ...state, removeOn: action.payload };
    case "CHANGE_REMOVE_INPUT":
      return { ...state, removeInput: action.payload };
    case "CHANGE_SHEET_ON":
      return { ...state, sheetOn: action.payload };
    case "CHANGE_SHEET_INPUT":
      return { ...state, sheetInput: action.payload };
    case "CHANGE_QUESTION_INPUT":
      return { ...state, questionInput: action.payload };
    case "CHANGE_QUESTION_ON":
      return { ...state, questionOn: action.payload };
    case "CHANGE_HINT_ON":
      return { ...state, hintOn: action.payload };
    case "CHANGE_VIDEO_ON":
      return { ...state, videoOn: action.payload };
    case "CHANGE_FULL_SCREEN_ON":
      return { ...state, fullScreenOn: action.payload };
    case "CHANGE_PLAY_ON":
      return { ...state, playOn: action.payload };
    case "CHANGE_CALCULATOR_ON":
      return { ...state, calculatorOn: action.payload };
    case "CHANGE_CALCULATOR_INPUT":
      return { ...state, calculatorInput: action.payload };
    case "CHANGE_ANSWER_ON":
      return { ...state, answerOn: action.payload };
    case "CHANGE_VOCAB_ON":
      return { ...state, vocabOn: action.payload };
    case "CHANGE_VOCAB_INPUT":
      return { ...state, vocabInput: action.payload };
    case "CHANGE_VOCAB_ANSWER_ON":
      return { ...state, vocabAnswerOn: action.payload };
    case "CHANGE_SHOW_ON":
      return { ...state, showOn: action.payload };
    default:
      return state;
  }
};

export default reducer;
