import React from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import Speech from "speak-tts";
import {
  changeClearOn,
  changeClearInput,
  changeSelectOn,
  changeSelectInput,
  changeJoinOn,
  changeJoinInput,
  changeRemoveOn,
  changeRemoveInput,
  changeSheetOn,
  changeSheetInput,
  changeQuestionOn,
  changeQuestionInput,
  changeHintOn,
  changeVideoOn,
  changeFullScreenOn,
  changePlayOn,
  changeCalculatorOn,
  changeCalculatorInput,
  changeAnswerOn,
  changeVocabOn,
  changeVocabInput,
  changeVocabAnswerOn,
  changeShowOn,
} from "../actions/action";
import { connect } from "react-redux";

const SpeechToText = (props) => {
  const commands = [
    {
      command: "reload",
      callback: () => {
        window.location.reload();
      },
    },
    {
      command: "show",
      callback: () => {
        resetTranscript();
        props.changeShowOn(true);
      },
    },
    {
      command: "hide",
      callback: () => {
        resetTranscript();
        props.changeShowOn(false);
      },
    },
    {
      command: "reset",
      callback: () => {
        resetTranscript();
      },
    },
    {
      command: "close",
      callback: () => {
        resetTranscript();
        SpeechRecognition.stopListening();
      },
    },
    {
      command: "means",
      callback: () => {
        props.changeVocabOn(false);
        props.changeVocabAnswerOn(true);
      },
    },

    {
      command: "dms",
      callback: () => {
        textToSpeech("yes");
        resetTranscript();
        props.changeVocabOn(true);
      },
    },
    {
      command: "thanks",
      callback: () => {
        textToSpeech("welcome");
        resetTranscript();
      },
    },
    {
      command: "clear",
      callback: () => {
        textToSpeech("what");
        resetTranscript();
        props.changeClearOn(true);
      },
    },
    {
      command: "refresh",
      callback: () => {
        resetTranscript();
        props.changeClearOn(false);
        props.changeSelectOn(false);
        props.changeJoinOn(false);
        props.changeRemoveOn(false);
        props.changeSheetOn(false);
        props.changeQuestionOn(false);
        props.changeAnswerOn(false);
        props.changeCalculatorOn(false);
        props.changeVocabAnswerOn(false);
        setTimeout(() => {
          SpeechRecognition.stopListening();
        }, 2000);
        setTimeout(() => {
          listenContinuously();
        }, 10000);
      },
    },
    {
      command: "select",
      callback: () => {
        textToSpeech("whoom");
        resetTranscript();
        props.changeSelectOn(true);
      },
    },
    {
      command: "activate",
      callback: () => {
        textToSpeech("whoom");
        resetTranscript();
        props.changeSelectOn(true);
      },
    },
    {
      command: "join",
      callback: () => {
        textToSpeech("whoom");
        resetTranscript();
        props.changeJoinOn(true);
      },
    },
    {
      command: "drawing",
      callback: () => {
        textToSpeech("whoom");
        resetTranscript();
        props.changeJoinOn(true);
      },
    },
    {
      command: "remove",
      callback: () => {
        textToSpeech("whoom");
        resetTranscript();
        props.changeRemoveOn(true);
      },
    },
    {
      command: "open",
      callback: () => {
        textToSpeech("what");
        resetTranscript();
        props.changeSheetOn(true);
      },
    },
    {
      command: "question",
      callback: () => {
        resetTranscript();
        props.changeQuestionOn(true);
      },
    },
    {
      command: "christian",
      callback: () => {
        resetTranscript();
        props.changeQuestionOn(true);
      },
    },
    {
      command: "hint",
      callback: () => {
        resetTranscript();
        props.changeHintOn(true);
      },
    },
    {
      command: "video",
      callback: () => {
        resetTranscript();
        props.changeVideoOn(true);
      },
    },

    {
      command: "maximize",
      callback: () => {
        resetTranscript();
        props.changeFullScreenOn(true);
      },
    },

    {
      command: "minimise",
      callback: () => {
        resetTranscript();
        props.changeFullScreenOn(false);
      },
    },
    {
      command: "play",
      callback: () => {
        resetTranscript();
        props.changePlayOn(true);
      },
    },

    {
      command: "stop",
      callback: () => {
        resetTranscript();
        props.changePlayOn(false);
      },
    },

    {
      command: "answer",
      callback: () => {
        props.changeCalculatorOn(false);
        props.changeAnswerOn(true);
      },
    },
    {
      command: "is equals to",
      callback: () => {
        props.changeCalculatorOn(false);
        props.changeAnswerOn(true);
      },
    },
    {
      command: "calculator",
      callback: () => {
        textToSpeech("yes sir");
        resetTranscript();
        props.changeAnswerOn(false);
        props.changeCalculatorOn(true);
      },
    },
  ];

  const { transcript, resetTranscript } = useSpeechRecognition({
    commands,
  });

  const listenContinuously = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        SpeechRecognition.startListening({
          continuous: true,
          language: "en-GB",
        });
      })
      .catch(function (err) {
        alert("no mic available");
      });
  };

  const textToSpeech = (x) => {
    const speech = new Speech();
    speech.speak({
      text: x,
    });
  };

  return (
    <div>
      <button className="pcorrecttotal" onClick={() => listenContinuously()}>
        start
      </button>
      <button
        className="pincorrecttotal"
        onClick={() => SpeechRecognition.stopListening()}
      >
        stop
      </button>
      <button className="pnormaltotal" onClick={() => resetTranscript()}>
        reset
      </button>
      <br></br>
      <input className="speechinput" value={transcript} />
      {props.clearOn ? props.changeClearInput(transcript.toLowerCase()) : null}
      {props.selectOn
        ? props.changeSelectInput(transcript.toLowerCase())
        : null}
      {props.joinOn ? props.changeJoinInput(transcript.toLowerCase()) : null}
      {props.removeOn
        ? props.changeRemoveInput(transcript.toLowerCase())
        : null}
      {props.sheetOn ? props.changeSheetInput(transcript.toUpperCase()) : null}
      {props.questionOn
        ? props.changeQuestionInput(transcript.toUpperCase())
        : null}
      {props.calculatorOn
        ? props.changeCalculatorInput(transcript.toLowerCase())
        : null}
      {props.vocabOn ? props.changeVocabInput(transcript.toLowerCase()) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    clearOn: state.clearOn,
    selectOn: state.selectOn,
    joinOn: state.joinOn,
    removeOn: state.removeOn,
    sheetOn: state.sheetOn,
    questionOn: state.questionOn,
    hintOn: state.hintOn,
    videoOn: state.videoOn,
    fullScreenOn: state.fullScreenOn,
    playOn: state.playOn,
    calculatorOn: state.calculatorOn,
    calculatorInput: state.calculatorInput,
    answerOn: state.answerOn,
    vocabOn: state.vocabOn,
    vocabInput: state.vocabInput,
    vocabAnswerOn: state.vocabAnswerOn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeClearOn: (clearOn) => {
      dispatch(changeClearOn(clearOn));
    },
    changeClearInput: (clearInput) => {
      dispatch(changeClearInput(clearInput));
    },
    changeSelectOn: (selectOn) => {
      dispatch(changeSelectOn(selectOn));
    },
    changeSelectInput: (selectInput) => {
      dispatch(changeSelectInput(selectInput));
    },
    changeJoinOn: (joinOn) => {
      dispatch(changeJoinOn(joinOn));
    },
    changeJoinInput: (joinInput) => {
      dispatch(changeJoinInput(joinInput));
    },
    changeRemoveOn: (removeOn) => {
      dispatch(changeRemoveOn(removeOn));
    },
    changeRemoveInput: (removeInput) => {
      dispatch(changeRemoveInput(removeInput));
    },
    changeSheetOn: (sheetOn) => {
      dispatch(changeSheetOn(sheetOn));
    },
    changeSheetInput: (sheetInput) => {
      dispatch(changeSheetInput(sheetInput));
    },
    changeQuestionOn: (questionOn) => {
      dispatch(changeQuestionOn(questionOn));
    },
    changeQuestionInput: (questionInput) => {
      dispatch(changeQuestionInput(questionInput));
    },
    changeHintOn: (hintOn) => {
      dispatch(changeHintOn(hintOn));
    },
    changeVideoOn: (videoOn) => {
      dispatch(changeVideoOn(videoOn));
    },
    changeFullScreenOn: (fullScreenOn) => {
      dispatch(changeFullScreenOn(fullScreenOn));
    },

    changePlayOn: (playOn) => {
      dispatch(changePlayOn(playOn));
    },
    changeCalculatorOn: (calculatorOn) => {
      dispatch(changeCalculatorOn(calculatorOn));
    },
    changeCalculatorInput: (calculatorInput) => {
      dispatch(changeCalculatorInput(calculatorInput));
    },
    changeAnswerOn: (answerOn) => {
      dispatch(changeAnswerOn(answerOn));
    },
    changeVocabOn: (vocabOn) => {
      dispatch(changeVocabOn(vocabOn));
    },
    changeVocabInput: (vocabInput) => {
      dispatch(changeVocabInput(vocabInput));
    },
    changeVocabAnswerOn: (vocabAnswerOn) => {
      dispatch(changeVocabAnswerOn(vocabAnswerOn));
    },
    changeShowOn: (showOn) => {
      dispatch(changeShowOn(showOn));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeechToText);
