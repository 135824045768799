import React from "react";
import { connect } from "react-redux";
import { changeCurrentUser, changeCurrentUserLevel } from "../actions/action";
import axios from "axios";
import "./Login.css";
import Speech from "speak-tts";

class Home extends React.Component {
  constructor(props) {
    super(props);
    let logged_in = false;

    const token = localStorage.getItem("token");
    if (token) logged_in = true;
    this.state = {
      levelList: [],
      needHelp: false,
      logged_in,
      neederList: [],
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ needHelp: true });
      axios
        .get(
          "https://backend.mccubeindia.com/user/activeuser/?sheet_name=help",
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => this.setState({ neederList: res.data }));
    }, 15000);
    if (this.state.logged_in) {
      let handleErrors = (response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      };

      fetch("https://backend.mccubeindia.com/validate/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          fetch("https://backend.mccubeindia.com/user/current", {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
            .then(handleErrors)
            .then((res) => res.json())
            .then((json) => {
              if (json.username) {
                this.props.changeusername(json.username);
              }
              fetch("https://backend.mccubeindia.com/refresh/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  token: localStorage.getItem("token"),
                }),
              })
                .then(handleErrors)
                .then((res) => res.json())
                .then((json) => {
                  localStorage.setItem("token", json.token);
                })
                .then(() => this.levelList())
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              localStorage.removeItem("token");
            });
        })
        .catch((error) => {
          localStorage.removeItem("token");
        });
    }
  }

  textToSpeech = () => {
    const needer = this.state.neederList.map((i) => i.user_name);
    const announcement = needer.length > 0 ? `${needer}  need  some  help` : "";
    const speech = new Speech();
    speech.setLanguage("en-IN");
    speech.speak({
      text: announcement,
      listeners: {
        onend: () => {
          this.setState({ needHelp: false });
        },
      },
    });
  };

  helped = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/user/activeuser/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then(() =>
        this.setState({
          neederList: this.state.neederList.filter((i) => i.id !== x),
        })
      );
  };

  renderNames = () => {
    const list = this.state.neederList;
    return list.map((item) => (
      <button
        style={{
          color: "white",
          backgroundColor: "blueviolet",
          border: "none",
          borderRadius: 10,
        }}
        onClick={() => this.helped(item.id)}
      >
        {item.user_name}
      </button>
    ));
  };

  render() {
    return (
      <div>
        {this.state.needHelp ? this.textToSpeech() : this.renderNames()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
    userlevel: state.currentUserLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeusername: (currentUser) => {
      dispatch(changeCurrentUser(currentUser));
    },
    changeuserlevel: (currentUserLevel) => {
      dispatch(changeCurrentUserLevel(currentUserLevel));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
