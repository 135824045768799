import React, { Component } from "react";
import axios from "axios";
import Modal from "./monitorModal";

export default class monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopicSheets: false,
      showTestPapers: false,
      showTestCategory: false,
      showTestSubCategory: false,
      resultList: [],
      examName: "",
      sheetQuestionList: [],
      testQuestionList: [],
      level: "",
      activeItem: [],
      modal: false,
    };
  }

  componentDidMount() {
    this.sheetQuestionsList();
    this.testQuestionsList();
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  getResult = (x) => {
    this.setState({ level: x });
    if (this.state.showTopicSheets) {
      axios
        .get(`https://backend.mccubeindia.com/sheet/sheetname/?level=${x}`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) =>
          this.setState({
            resultList: res.data,
          })
        )
        .catch((err) => console.log(err));
    } else {
      if (this.state.showTestSubCategory) {
        axios
          .get(
            `https://backend.mccubeindia.com/testpaper/testpaper/?level=${x}&test_type=${this.state.examName}`,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) =>
            this.setState({
              resultList: res.data,
            })
          )
          .catch((err) => console.log(err));
      } else {
        axios
          .get(
            `https://backend.mccubeindia.com/testpaper/testpaper/?exam=${this.state.examName}&test_type=${x}`,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) =>
            this.setState({
              resultList: res.data,
            })
          )
          .catch((err) => console.log(err));
      }
    }
  };

  sheetQuestionsList = () => {
    axios
      .get("https://backend.mccubeindia.com/sheet/questions/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ sheetQuestionList: res.data }));
  };
  testQuestionsList = () => {
    axios
      .get("https://backend.mccubeindia.com/testpaper/questions/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ testQuestionList: res.data }));
  };
  sheetQuestionsHint = (x) => {
    const list = this.state.sheetQuestionList.filter(
      (item) => (item.sheet_name === x) & (item.hint_image !== "1")
    );
    return list.length;
  };
  sheetQuestionsHintVideo = (x) => {
    const list = this.state.sheetQuestionList.filter(
      (item) =>
        (item.sheet_name === x) &
        (item.hint_video !== "https://www.youtube.com/embed/yqWX86uT5jM")
    );

    return list.length;
  };
  testQuestionsSolution = (x) => {
    const list = this.state.testQuestionList.filter(
      (item) => (item.test_name === x) & (item.solution_image !== "1")
    );
    return list.length;
  };
  testQuestionsSolutionVideo = (x) => {
    const list = this.state.testQuestionList.filter(
      (item) => (item.test_name === x) & (item.solution_video !== "")
    );
    return list.length;
  };

  sheetDetail = (x) => {
    const list = this.state.sheetQuestionList.filter(
      (item) => item.sheet_name === x
    );
    this.setState({ activeItem: list, modal: !this.state.modal });
  };

  testDetail = (x) => {
    const list = this.state.testQuestionList.filter(
      (item) => item.test_name === x
    );
    this.setState({ activeItem: list, modal: !this.state.modal });
  };

  updateConcept = (x) => {
    const id = x.id;
    const item = {
      ...x,
      concept_image: "/images/" + x.sheet_name.replace(/[/]/g, "-") + ".jpg",
    };
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/sheet/sheetname/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.getResult(this.state.level));
  };
  updateConceptVideo = (x) => {
    const id = x.id;
    const item = {
      ...x,
      concept_video: "/videos/" + x.sheet_name.replace(/[/]/g, "-") + ".mp4",
    };
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/sheet/sheetname/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.getResult(this.state.level));
  };
  deleteConcept = (x) => {
    const id = x.id;
    const item = {
      ...x,
      concept_image: "1",
    };
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/sheet/sheetname/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.getResult(this.state.level));
  };
  deleteConceptVideo = (x) => {
    const id = x.id;
    const item = {
      ...x,
      concept_video: "",
    };
    delete item.id;
    axios
      .put(`https://backend.mccubeindia.com/sheet/sheetname/${id}/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.getResult(this.state.level));
  };
  updateHint = (x) => {
    const id = x.id;
    const item = {
      ...x,
      hint_image:
        "/images/" + x.sheet_name.replace(/[/]/g, "-") + x.question_no + ".jpg",
    };
    delete item.id;
    axios.put(`https://backend.mccubeindia.com/sheet/questions/${id}/`, item, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
  };
  updateHintVideo = (x) => {
    const id = x.id;
    const item = {
      ...x,
      hint_video:
        "/videos/" + x.sheet_name.replace(/[/]/g, "-") + x.question_no + ".mp4",
    };
    delete item.id;
    axios.put(`https://backend.mccubeindia.com/sheet/questions/${id}/`, item, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
  };
  updateSolution = (x) => {
    const id = x.id;
    const item = {
      ...x,
      solution_image:
        "/images/" + x.sheet_name.replace(/[/]/g, "-") + x.question_no + ".jpg",
    };
    delete item.id;
    axios.put(
      `https://backend.mccubeindia.com/testpaper/questions/${id}/`,
      item,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      }
    );
  };
  updateSolutionVideo = (x) => {
    const id = x.id;
    const item = {
      ...x,
      solution_video:
        "/videos/" + x.sheet_name.replace(/[/]/g, "-") + x.question_no + ".mp4",
    };
    delete item.id;
    axios.put(
      `https://backend.mccubeindia.com/testpaper/questions/${id}/`,
      item,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      }
    );
  };

  deleteHint = (x) => {
    const id = x.id;
    const item = {
      ...x,
      hint_image: "1",
    };
    delete item.id;
    axios.put(`https://backend.mccubeindia.com/sheet/questions/${id}/`, item, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
  };
  deleteHintVideo = (x) => {
    const id = x.id;
    const item = {
      ...x,
      hint_video: "https://www.youtube.com/embed/yqWX86uT5jM",
    };
    delete item.id;
    axios.put(`https://backend.mccubeindia.com/sheet/questions/${id}/`, item, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
  };
  deleteSolution = (x) => {
    const id = x.id;
    const item = {
      ...x,
      solution_image: "1",
    };
    delete item.id;
    axios.put(
      `https://backend.mccubeindia.com/testpaper/questions/${id}/`,
      item,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      }
    );
  };
  deleteSolutionVideo = (x) => {
    const id = x.id;
    const item = {
      ...x,
      solution_video: "",
    };
    delete item.id;
    axios.put(
      `https://backend.mccubeindia.com/testpaper/questions/${id}/`,
      item,
      {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      }
    );
  };

  renderList = () => {
    const newItems = this.state.resultList;
    return newItems.map((item, index) => (
      <>
        {this.state.showTopicSheets ? (
          <tr>
            <th scope="row">
              <p className="namelist">{index + 1}</p>
            </th>
            <th scope="row">
              <p className="namelist">{item.sheet_name}</p>
            </th>
            <th
              scope="row"
              style={{
                backgroundColor:
                  item.concept_image !== "1" ? "#40e0d0" : "white",
              }}
              onClick={() =>
                item.concept_image !== "1"
                  ? this.deleteConcept(item)
                  : this.updateConcept(item)
              }
            >
              <p className="namelist">
                {item.concept_image !== "1" ? "remove" : "update"}
              </p>
            </th>
            <th
              scope="row"
              style={{
                backgroundColor:
                  item.concept_video !== "" ? "#40e0d0" : "white",
              }}
              onClick={() =>
                item.concept_video !== ""
                  ? this.deleteConceptVideo(item)
                  : this.updateConceptVideo(item)
              }
            >
              <p className="namelist">
                {item.concept_video !== "" ? "remove" : "update"}
              </p>
            </th>
            <th scope="row">
              <p className="namelist">{item.no_of_questions}</p>
            </th>
            <th
              scope="row"
              style={{
                backgroundColor:
                  item.no_of_questions ===
                  this.sheetQuestionsHint(item.sheet_name)
                    ? "#40e0d0"
                    : "white",
              }}
              onClick={() => this.sheetDetail(item.sheet_name)}
            >
              <p className="namelist">
                {this.sheetQuestionsHint(item.sheet_name)}
              </p>
            </th>
            <th
              scope="row"
              style={{
                backgroundColor:
                  item.no_of_questions ===
                  this.sheetQuestionsHintVideo(item.sheet_name)
                    ? "#40e0d0"
                    : "white",
              }}
              onClick={() => this.sheetDetail(item.sheet_name)}
            >
              <p className="namelist">
                {this.sheetQuestionsHintVideo(item.sheet_name)}
              </p>
            </th>
          </tr>
        ) : (
          <tr>
            <th scope="row">
              <p className="namelist">{index + 1}</p>
            </th>
            <th scope="row" onClick={() => this.testDetail(item.test_name)}>
              <p className="namelist">{item.test_name}</p>
            </th>
            <th scope="row">
              <p className="namelist">{item.no_of_questions}</p>
            </th>
            <th
              scope="row"
              style={{
                backgroundColor:
                  item.no_of_questions ===
                  this.testQuestionsSolution(item.test_name)
                    ? "#40e0d0"
                    : "white",
              }}
              onClick={() => this.testDetail(item.test_name)}
            >
              <p className="namelist">
                {this.testQuestionsSolution(item.test_name)}
              </p>
            </th>
            <th
              scope="row"
              style={{
                backgroundColor:
                  item.no_of_questions ===
                  this.testQuestionsSolutionVideo(item.test_name)
                    ? "#40e0d0"
                    : "white",
              }}
              onClick={() => this.testDetail(item.test_name)}
            >
              <p className="namelist">
                {this.testQuestionsSolutionVideo(item.test_name)}
              </p>
            </th>
          </tr>
        )}
      </>
    ));
  };

  render() {
    return (
      <div>
        <div>
          <button
            className={
              this.state.showTopicSheets ? "pselectedstudents" : "pallstudents"
            }
            onClick={() =>
              this.setState({
                showTopicSheets: true,
                showTestPapers: false,
                showTestCategory: false,
                showTestSubCategory: false,
                resultList: [],
                level: "",
              })
            }
          >
            topic sheets
          </button>
          <button
            className={
              this.state.showTestPapers ? "pselectedstudents" : "pallstudents"
            }
            onClick={() =>
              this.setState({
                showTopicSheets: false,
                showTestPapers: true,
                showTestCategory: false,
                showTestSubCategory: false,
                resultList: [],
                level: "",
              })
            }
          >
            test papers
          </button>
        </div>
        {this.state.showTopicSheets ? (
          <div>
            <button
              className={
                this.state.level === "BASIC"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("BASIC")}
            >
              BASIC
            </button>
            <button
              className={
                this.state.level === "FOUNDATION"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("FOUNDATION")}
            >
              FOUNDATION
            </button>
            <button
              className={
                this.state.level === "MEDIUM"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("MEDIUM")}
            >
              MEDIUM
            </button>
            <button
              className={
                this.state.level === "ADVANCED"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("ADVANCED")}
            >
              ADVANCED
            </button>
          </div>
        ) : null}
        {this.state.showTestPapers ? (
          <div>
            <button
              className={
                this.state.examName === "CAT"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: true,
                  showTestSubCategory: false,
                  resultList: [],
                  examName: "CAT",
                })
              }
            >
              CAT
            </button>
            <button
              className={
                this.state.examName === "CMAT"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: true,
                  showTestSubCategory: false,
                  resultList: [],
                  examName: "CMAT",
                })
              }
            >
              CMAT
            </button>
            <button
              className={
                this.state.examName === "CET"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: true,
                  showTestSubCategory: false,
                  resultList: [],
                  examName: "CET",
                })
              }
            >
              CET
            </button>
            <button
              className={
                this.state.examName === "NMAT"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: true,
                  showTestSubCategory: false,
                  resultList: [],
                  examName: "NMAT",
                })
              }
            >
              NMAT
            </button>
            <button
              className={
                this.state.examName === "SNAP"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: true,
                  showTestSubCategory: false,
                  resultList: [],
                  examName: "SNAP",
                })
              }
            >
              SNAP
            </button>
            <button
              className={
                this.state.examName === "IPM"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: true,
                  showTestSubCategory: false,
                  resultList: [],
                  examName: "IPM",
                })
              }
            >
              IPM
            </button>
            <button
              className={
                this.state.examName === "weekly"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() =>
                this.setState({
                  showTestCategory: false,
                  showTestSubCategory: true,
                  resultList: [],
                  examName: "weekly",
                })
              }
            >
              WEEKLY
            </button>
          </div>
        ) : null}
        {this.state.showTestCategory ? (
          <div>
            <button
              className={
                this.state.level === "subject"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("subject")}
            >
              SECTIONAL
            </button>
            <button
              className={
                this.state.level === "mock"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("mock")}
            >
              MOCK
            </button>
            <button
              className={
                this.state.level === "previous_year_paper"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("previous_year_paper")}
            >
              PREVIOUS YEAR
            </button>
          </div>
        ) : null}
        {this.state.showTestSubCategory ? (
          <div>
            {" "}
            <button
              className={
                this.state.level === "BASIC"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("BASIC")}
            >
              BASIC
            </button>
            <button
              className={
                this.state.level === "FOUNDATION"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("FOUNDATION")}
            >
              FOUNDATION
            </button>
            <button
              className={
                this.state.level === "MEDIUM"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("MEDIUM")}
            >
              MEDIUM
            </button>
            <button
              className={
                this.state.level === "ADVANCED"
                  ? "pselectedstudents"
                  : "pallstudents"
              }
              onClick={() => this.getResult("ADVANCED")}
            >
              ADVANCED
            </button>
          </div>
        ) : null}
        <ul className="list-group list-group-flush">
          {this.state.showTopicSheets ? (
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">
                    <p className="namelist">N0.</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Name</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Concept</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Concept video</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Total questions</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Hints</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Hint videos</p>
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderList()}</tbody>
            </table>
          ) : this.state.showTestPapers ? (
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">
                    <p className="namelist">N0.</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Name</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Total questions</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Solutions</p>
                  </th>
                  <th scope="col">
                    <p className="numberlist">Solution Videos</p>
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderList()}</tbody>
            </table>
          ) : null}
        </ul>
        {this.state.modal ? (
          <Modal
            activeItem={this.state.activeItem}
            onSaveHint={
              this.state.showTopicSheets ? this.updateHint : this.updateSolution
            }
            onSaveHintVideo={
              this.state.showTopicSheets
                ? this.updateHintVideo
                : this.updateSolutionVideo
            }
            onDeleteHint={
              this.state.showTopicSheets ? this.deleteHint : this.deleteSolution
            }
            onDeleteHintVideo={
              this.state.showTopicSheets
                ? this.deleteHintVideo
                : this.deleteSolutionVideo
            }
            toggle={this.toggle}
            topicSheet={this.state.showTopicSheets}
          />
        ) : null}
      </div>
    );
  }
}
