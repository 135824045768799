import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

export default class monitorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: this.props.activeItem,
      hints: this.props.topicSheet
        ? this.props.activeItem
            .filter((item) => item.hint_image !== "1")
            .map((item) => item.question_no)
        : this.props.activeItem
            .filter((item) => item.solution_image !== "1")
            .map((item) => item.question_no),
      videos: this.props.topicSheet
        ? this.props.activeItem
            .filter(
              (item) =>
                item.hint_video !== "https://www.youtube.com/embed/yqWX86uT5jM"
            )
            .map((item) => item.question_no)
        : this.props.activeItem
            .filter((item) => item.solution_video !== "")
            .map((item) => item.question_no),
    };
  }

  render() {
    const {
      onSaveHint,
      onSaveHintVideo,
      onDeleteHint,
      onDeleteHintVideo,
      topicSheet,
    } = this.props;
    return (
      <Modal isOpen={true}>
        <ModalHeader>
          {" "}
          {topicSheet
            ? this.state.activeItem[0].sheet_name
            : this.state.activeItem[0].test_name}
        </ModalHeader>
        <ModalBody>
          <Form>
            {this.state.activeItem.map((item) => (
              <FormGroup>
                <Label for="name">{item.question_no}</Label>{" "}
                {topicSheet ? (
                  this.state.hints.includes(item.question_no) ? (
                    <>
                      {" "}
                      <Label for="hint">Hint</Label>{" "}
                      <Button
                        onClick={() => {
                          onDeleteHint(item);
                          this.setState({
                            hints: this.state.hints.filter(
                              (i) => i !== item.question_no
                            ),
                          });
                        }}
                      >
                        remove
                      </Button>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Label for="hint">Hint</Label>{" "}
                      <Button
                        color="success"
                        onClick={() => {
                          onSaveHint(item);
                          this.setState({
                            hints: [...this.state.hints, item.question_no],
                          });
                        }}
                      >
                        Update
                      </Button>{" "}
                    </>
                  )
                ) : this.state.hints.includes(item.question_no) ? (
                  <>
                    {" "}
                    <Label for="solution">Solution</Label>{" "}
                    <Button
                      onClick={() => {
                        onDeleteHint(item);
                        this.setState({
                          hints: this.state.hints.filter(
                            (i) => i !== item.question_no
                          ),
                        });
                      }}
                    >
                      remove
                    </Button>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <Label for="solution">Solution</Label>{" "}
                    <Button
                      color="success"
                      onClick={() => {
                        onSaveHint(item);
                        this.setState({
                          hints: [...this.state.hints, item.question_no],
                        });
                      }}
                    >
                      Update
                    </Button>{" "}
                  </>
                )}
                {topicSheet ? (
                  this.state.videos.includes(item.question_no) ? (
                    <>
                      {" "}
                      <Label for="hint video">Hint video</Label>{" "}
                      <Button
                        onClick={() => {
                          onDeleteHintVideo(item);
                          this.setState({
                            videos: this.state.videos.filter(
                              (i) => i !== item.question_no
                            ),
                          });
                        }}
                      >
                        remove
                      </Button>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Label for="hint video">Hint video</Label>{" "}
                      <Button
                        color="success"
                        onClick={() => {
                          onSaveHintVideo(item);
                          this.setState({
                            videos: [...this.state.videos, item.question_no],
                          });
                        }}
                      >
                        Update
                      </Button>{" "}
                    </>
                  )
                ) : this.state.videos.includes(item.question_no) ? (
                  <>
                    {" "}
                    <Label for="solution video">Solution video</Label>{" "}
                    <Button
                      onClick={() => {
                        onDeleteHintVideo(item);
                        this.setState({
                          videos: this.state.videos.filter(
                            (i) => i !== item.question_no
                          ),
                        });
                      }}
                    >
                      remove
                    </Button>{" "}
                  </>
                ) : (
                  <>
                    {" "}
                    <Label for="solution video">Solution video</Label>{" "}
                    <Button
                      color="success"
                      onClick={() => {
                        onSaveHintVideo(item);
                        this.setState({
                          videos: [...this.state.videos, item.question_no],
                        });
                      }}
                    >
                      Update
                    </Button>{" "}
                  </>
                )}
              </FormGroup>
            ))}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => {
              this.props.toggle();
              window.location.reload();
            }}
          >
            save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
