import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";

const CustomNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar className="navbar" light expand="md">
        <NavbarBrand style={{ color: "whitesmoke" }} href="/">
          MCCUBE
        </NavbarBrand>
        <NavbarToggler
          style={{ backgroundColor: "whitesmoke" }}
          onClick={toggle}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink
                to={"/allstudents"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                All Student
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to={"/mccubestudents"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                MCCUBE Students
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/liveperformance"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Live Performance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/monitor"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Eklavya Monitor
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/quiz"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Quiz
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/logout"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
